import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function MicrobiotePeau() {

	return (
		<>
			<PageTitle>Microbiote <br /> et peau</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first"><span className="bold">Le microbiote cutané représente l&apos;ensemble des microorganismes présents à la surface de la peau</span>.</span></span>
					<br />
					<span><span className="second">À travers nos recherches, nous mettons en évidence les microorganismes commensaux de la peau
						qui ont un <span className="bold">rôle clé dans l&apos;écosystème cutané</span>.</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/MicrobioteEtPeau/microbioteEtPeau1.png"} imageSide="right" >
				<>
					<p className="paddingTopBottom">
						Nous travaillons avec des partenaires académiques pour <span className="bold">comprendre l&apos;impact du microbiote cutané sur les principales fonctions biologiques de la peau</span>.
					</p>
					<div id="displayImageHighlight" className="marginTopBottom first">
						<div className="paddingTopBottom">
							<p>
								Ces recherches sont indispensables pour nous aider dans la conception de nos produits afin de <span className="bold">garantir l&apos;équilibre du microbiote et préserver les souches sentinelles indispensables</span> à un fonctionnement optimal des interactions microbiote&nbsp;-&nbsp;peau.
							</p>
						</div>
					</div>

					<p className="paddingTopBottom">
						Ainsi nous avons pu mettre en évidence le rôle d&apos;une souche microbienne, le <q><i>&nbsp;Staphylococcus epidermidis&nbsp;</i></q> dans la régulation de l&apos;hydratation.
					</p>

				</>
			</PageDisplayImage>
		</>
	)
}
