import { useState } from "react";
import "../../style.css";
import "./pageStyle.css";
import subtitleFR from "../../videoSubtitles-fr.js"
import subtitleEN from "../../videoSubtitles-en.js"
import { useLanguage } from '../../Contexts/LanguageContext.jsx';

export default function PageDisplayImage({ children, imgSrc = "", videoSrc = "", videoName = "", videoSize = "large", isVideo = false, z, title, imageSide = "left", backgroundImage = "linear-gradient(#eee8e2, #fff)", backgroundColor = "transparent", hasSubtitle = false, style = {} }) {
	const { currentLanguage, setCurrentLanguage } = useLanguage();

	return (<>
		<div
			id="page-background"
			className={`displayImage ${backgroundImage !== "none" ? "gradient" : backgroundColor === "transparent" ? "transparent" : backgroundColor === "#BA0D2F" ? "red" : "beige"}`}
			style={style}
		>
			{title &&
				<div className="page-block" style={{ paddingBottom: '60px' }}>
					<h1 className="paddingTop">{title}</h1>
				</div>
			}
			<div id="displayImageWrapper" className={imageSide === "right" ? "page-block flexReverse" : "page-block flex"} >
				{!isVideo ?
					<div id="displayImage" className={imageSide} style={{ backgroundImage: `url(${imgSrc})` }} />
					:
					<div id="displayImage" className={`${imageSide} ${videoSize}`} >
						<video
							autoPlay muted loop
							style={{ objectFit: "cover", borderRadius: "5px", width: "100%" }}
						>
							<source src={videoSrc} type="video/mp4" />
							{hasSubtitle && currentLanguage == 'fr' && (
								<track
									label="Français"
									kind="subtitles"
									srclang="fr"
									src={subtitleFR[videoName]}
									default />
							)}
							{hasSubtitle && currentLanguage == 'en' && (
								<track
									label="English"
									kind="subtitles"
									srclang="en"
									src={subtitleEN[videoName]}
									default />
							)}
						</video>
					</div>
				}
				<div id="displayImageText" className={imageSide}>
					{children}
				</div>
			</div>
		</div >
	</>
	)
}
