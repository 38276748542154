import React from 'react';
import ReactDOM from "react-dom/client";
import { render } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App.jsx";
import { LanguageProvider } from './Contexts/LanguageContext.jsx';
import "./style.css";

render(
	<React.StrictMode>
		<BrowserRouter>
			<LanguageProvider>
				<App />
			</LanguageProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

/*
const root = ReactDOM.createRoot(document.querySelector('#root'))

root.render(
  <BrowserRouter>
	  <LanguageProvider>
		  <App />
	  </LanguageProvider>
  </BrowserRouter>
)
*/