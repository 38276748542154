import { useState, useEffect, useRef } from "react";

import "../../style.css";
import "./pageStyle.css";
import MenuButton from "../General/MenuButton";

export default function PageDown() {
	const [isAtBottom, setIsAtBottom] = useState(false);

	const navigateDown = function () {
		var container = document.getElementById('pageWrapper');

		container.scrollBy({
			top: 500,
			behavior: 'smooth'
		});
	}

	useEffect(() => {
		const handleScroll = () => {
			var container = document.getElementById('pageWrapper');
			setIsAtBottom(container.scrollHeight - container.scrollTop === container.clientHeight);
		};

		const container = document.getElementById('pageWrapper');
		container.addEventListener('scroll', handleScroll);

		return () => {
			container.removeEventListener('scroll', handleScroll);
		};
	}, []);

	return (
		<div
			id="scrollButton"
			style={{
				position: "fixed",
				bottom: 25,
				top: "auto",
				right: 50,
				width: 50,
				zIndex: 4,
				display: isAtBottom ? 'none' : 'block',
				cursor: 'pointer'
			}}
			onClick={navigateDown}
		>
			<svg
				fill="#AAA"
				height="50px"
				width="50px"
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 330 330"
				stroke="#AAA"
				strokeWidth="0.0033">
				<g id="SVGRepo_bgCarrier" stroke-width="0"></g>
				<g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path> </g></svg>
		</div>
	)
}
