import PageText from "../../../Components/Pages/PageText.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";
import { useEffect, useState, useRef } from "react";

export default function Phytochimie() {

	const topRef = useRef()
	const [absoluteImageTop, setAbsoluteImageTop] = useState("20vw")
	useEffect(() => {
		setAbsoluteImageTop(String(topRef.current.getBoundingClientRect().top) + 100 + "px")
	}, [])

	return (
		<>
			<PageTitle>phytochimie<br /> & actifs</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Les plantes ont toujours été <span className="bold">une source d&apos;inspiration</span> pour Clarins et restent <span className="bold">au coeur de nos recherches</span>.</span></span>
					<br />
					<span><span className="second">Anthonin vous explique un peu plus en détail les activités de son laboratoire et <span className="bold">comment il transforme les plantes en extraits actifs</span>.</span></span>
				</p>
			</PageText>

			<div id="phytochimieAbsoluteImages" className="page-block absoluteImage" style={{ top: `${absoluteImageTop}` }} >
				<img src="/images/pages/Phytochimie/phytochimie2.png" />
			</div>
			<div ref={topRef} />

			<PageVideo videoName={"itwGori"} style={{ marginBottom: '100px' }}>
				Anthonin Gori<br />Chef de Projets Phytochimie
			</PageVideo>

			<PageDisplayImage imageSide="center" imgSrc={"/images/pages/Phytochimie/phytochimie1.png"} title="Focus sur les techniques d&apos;extraction" style={{ paddingTop: '40px' }}>
				<p className="paddingTop">
					Nous privilégions les techniques éco responsables
					comme l&apos;utilisation de<span className="bold"> micro-ondes</span> ou de <span className="bold">CO2
						supercritique</span> qui permettent d&apos;extraire un large
					spectre de molécules en utilisant des <span className="bold">solvants
						renouvelables</span> comme de l&apos;eau ou les liquides
					internes des plantes.
				</p>
			</PageDisplayImage>
		</>
	)
}
