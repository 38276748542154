import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageBanner from "../../../Components/Pages/PageBanner.jsx";
import "../../../style.css";

export default function MaquillageSolaires() {

	return (
		<>
			<PageTitle>Maquillage <br />& solaires</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Ce laboratoire développe tous les produits faisant intervenir des <span className="bold">pigments colorés</span> ainsi que tous les produits incluant <span className="bold">une protection contre les rayonnements UV</span>.</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Maquillage/maquillage1.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" >
				<h1 className="paddingTopBottom">
					Développement du maquillage
				</h1>
				<p>
					Nos produits de maquillage allient les résultats make-up immédiats à l&apos;efficacité du soin.<br /><br />
					Leur développement requiert la même rigueur que celui des produits de soin, mais implique en plus une <span className="bold">expertise spécifique de coloriste</span>, qui permet à nos chimistes de développer des teintes et de vérifier leur stabilité au cours du temps.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Maquillage/maquillage2.png"} >
				<h1 className="paddingTop">
					Développement des produits solaires
				</h1>
				<p className="paddingTop">
					La formulation des produits solaires <span className="bold">est particulièrement complexe de par les contraintes réglementaires</span> (les solaires pouvant être considérés comme des médicaments dans certains pays) et techniques.
					<br /><br />
					Des <span className="bold">dizaines de tests vitro et cliniques sont nécessaires</span> pour valider la protection solaire d&apos;une formule.
				</p>
			</PageDisplayImage>

			<PageBanner imgSrc={"/images/pages/Maquillage/maquillage3.png"} >
				<span className="bold">L&apos;atteinte de hauts SPF sans transiger sur la sensorialité</span> attendue par nos consommateurs est un vrai challenge que nos experts relèvent avec brio, en sélectionnant les filtres solaires et les émollients adéquats.
			</PageBanner>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Découvrez nos autres laboratoires de formulation</h2>
			</PageText>
		</>
	)
}
