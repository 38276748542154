import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function LaboRmc() {

	return (
		<>
			<PageTitle>Raw <br /> MATERIAL CENTRE</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc1.png"} backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
				<p className="paddingTopBottom">
					To meet the challenges of on-going innovation at Clarins, <span className="bold">our formulation laboratories select new raw materials</span> (active ingredients, excipients, preservatives and fragrances) <span className="bold">to improve the performance, efficacy or sensoriality of our products</span>.
					<br /><br />
					Strict criteria, such as origin, naturalness and regulatory compliance, guide our choices.
				</p>
				<div id="displayImageHighlight" className="marginTopBottom first" >
					<div className="paddingTopBottom">
						<p>
							The RMC (Raw Material Center) laboratory is at the heart of these activities, with several missions :
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc2.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
				<h1 className="paddingTop">
					ANALYSIS OF CHANGES IN OUR RAW MATERIALS
				</h1>
				<p className="paddingTopBottom">
					Any change in supplier, production site or quality must be studied and validated by our R&D experts.
				</p>

				<h1 className="paddingTop">
					PRE-FORMULATION OF ACTIVE INGREDIENTS AND PRESERVATIVES
				</h1>
				<p className="paddingTop">
					Pre-assessment of new raw materials enables <span className="bold">early identification of instabilities</span> and incompatibilities, <span className="bold">saving development time</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc3.png"} >
				<>
					<h1 className="paddingTop">
						SECURING SUPPLIES
					</h1>
					<p className="paddingTopBottom">
						Approval of secondary sources and new suppliers is necessary to secure supplies in the event of shortages at our regular suppliers.
					</p>

					<h1 className="paddingTop">
						RM DATABASE
					</h1>
					<p className="paddingTop">
						<span className="bold">Mastering our data management tools</span> and ensuring their accuracy and reliability is a key challenge, given the new regulatory constraints and the volume of raw materials involved.
					</p>
				</>
			</PageDisplayImage>
		</>
	)
}
