import { useNavigate } from "react-router-dom";
import "../../style.css";
import "./pageStyle.css";

export default function PageSubsections({ subSections }) {
    const navigate = useNavigate();

    return (
        <div
            className="flex paddingBottom"
            style={{ alignItems: "center", justifyContent: "center", zIndex: 4 }}
        >
            {subSections.map((value, index) =>
                <button
                    key={index}
                    className="bubble"
                    onClick={() => navigate(value.path)}
                >
                    {value.name}
                </button>
            )}
        </div>
    )
}
