import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function StrategieInnovation() {

	return (
		<>
			<PageTitle>Nos<br />engagements</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first"><span className="bold">Bienvenue dans notre centre de Recherche & Développement.</span></span></span>
					<br />
					<span><span className="second">Nathalie Issachar, notre Directrice R&D, vous parle en quelques mots de nos activités
						et nos engagements pour une beauté plus responsable.</span></span>
				</p>
			</PageText>

			<PageVideo videoName={"itwIssachar"}>
				Nathalie Issachar<br />Directrice Recherche et Développement
			</PageVideo>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements1.png"} backgroundImage="none" backgroundColor="#eee8e2" style={{ marginTop: '100px', paddingTop: '40px' }}>
				<h1 className="paddingTop">
					La Recherche & Développement<br />en quelques chiffres
				</h1>

				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="padding" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left" }} >
							+150
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							NOUVEAUX PRODUITS<br />DEVELOPPÉS/AN
						</p>
					</div>
				</div>

				<div id="displayImageHighlight">
					<div className="padding" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left" }} >
							+100
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							ETUDES DE RECHERCHE/AN
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements2.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingBottom: '100px', paddingTop: '100px' }}>
				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="paddingTopBottom" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left", paddingLeft: "30vw" }} >
							+200
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							PLANTES DANS L'HERBIER<br />CLARINS
						</p>
					</div>
				</div>

				<div id="displayImageHighlight">
					<div className="paddingTopBottom" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left", paddingLeft: "30vw" }} >
							+400
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							NOUVELLES PLANTES<br />ETUDIÉES/AN
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements3.png"} imageSide="center">
				<div id="displayImageHighlight">
					<div className="padding">
						<h2 style={{ color: "white", paddingLeft: "2vw", textAlign: "left", width: "45vw" }} >
							+90%
						</h2>
						<p style={{ paddingLeft: "2vw" }} >
							INGREDIENTS D'ORIGINE<br />NATURELLE DANS LE SOIN
						</p>
					</div>
				</div>
			</PageDisplayImage>
		</>
	)
}
