import "../../style.css";
import "./pageStyle.css";

export default function PageTitle({children}) {

    return (
        <div id="page-title-wrapper-wrapper" >
            <div id="page-title-wrapper">
                <h1 id="page-title" className="title padding" >
                    {children}
                </h1>
            </div>
        </div>
    )
}
