import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function PoleEnregistrements() {

	return (
		<>
			<PageTitle>INTERNATIONAL<br />REGISTRATION & PIF</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Enregistrements/enregistrements1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					The international registrations and PIF department <span className="bold">handles the administrative formalities</span> required to market cosmetic products in the various countries in which they will be distributed.
					<br /><br />
					<span className="bold">The team is responsible for guaranteeing regulatory compliance in PIFs (product information files)</span> for the various types of cosmetic products entering a market and for managing sanitary registrations for materials and finished products in all countries where products are sold.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Enregistrements/enregistrements2.png"} style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingTopBottom">
					<span className="bold">The PIF is the <q>&nbsp;identity card&nbsp;</q></span> that allows a product to be marketed.
					<br /><br />
					It is made available to regulatory authorities on request. It must contain the following information from  various contributing departments (R&D, Industrial Operations, Pilot, Manufacturing, Quality...):
				</p>
				<div id="displayImageHighlight" className="marginTopBottom first">
					<div className="paddingTopBottom">
						<p>
							•  Product identification and description,
							<br /><br />
							• Composition and labelling, determination of PAO (period after opening) in compliance with current regulations,
							<br /><br />
							• Product specifications and stability,
							<br /><br />
							• SER (Safety Evaluation Report) to ensure that the product is perfectly safe under reasonably foreseeable conditions of use.
						</p>
					</div>
				</div>
				<p className="paddingTopBottom">
					<span className="bold">The department ensures that PIFs are complete</span> before products are placed on the market, and that they remain up to date throughout product life.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Discover our other centers</h2>
			</PageText>
		</>
	)
}
