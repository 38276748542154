import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function PoleEvaluation() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	return (
		<>
			<PageTitle>Sécurité <br />& vigilances</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Securite/securite1.png"} backgroundImage="none" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					L&apos;évaluation de la sécurité
				</h1>
				<p className="paddingTop">
					Le pôle évaluation de la sécurité et vigilances réalise <span className="bold">l&apos;évaluation toxicologique</span> de l&apos;ensemble des matières premières et actifs utilisés dans nos formules, leur conformité vis-à-vis des différentes réglementations lors de leur référencement et tout au long de la durée de vie du produit.
					<br /><br />
					Il procède à une <span className="bold">évaluation du risque</span>, c&apos;est-à-dire la probabilité qu&apos;un danger apparaisse en utilisant l&apos;ingrédient/produit en question.
					<br /><br />
					<span className="bold">Pour un produit cosmétique, le risque doit être nul pour la santé humaine, contrairement à un produit pharmaceutique.</span>
				</p>
			</PageDisplayImage>

			<PageText>
				<ul className="padding" >
					<li style={{ position: 'relative' }}>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite2.png")` }} />
						<div style={{ position: 'absolute', top: '50%', right: '-45px', fontSize: '28px', color: '#c2001a' }}>X</div>
					</li>
					<li style={{ position: 'relative' }}>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite3.png")` }} />
						<div style={{ position: 'absolute', top: '50%', right: '-45px', fontSize: '28px', color: '#c2001a' }}>=</div>
					</li>
					<li>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite4.png")` }} />
					</li>
				</ul>
			</PageText>

			<PageText backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingBottom" style={{ width: "100%" }}>
					Le pôle est également garant de la sécurité des produits finis (nouveaux et existants) au travers de la <span className="bold">validation toxicologique des formules</span> développées et de la rédaction de <span className="bold">rapports d&apos;évaluation de la sécurité (RES)</span>.
				</p>

				{currentLanguage === 'fr' ? (
					<div
						className="fullSizeImage"
						style={{ backgroundImage: `url("./images/pages/Securite/securite5.png")` }}
					/>
				) : (
					<div
						className="fullSizeImage"
						style={{ backgroundImage: `url("./images/pages/Securite/securite5_en.png")` }}
					/>
				)}

			</PageText >

			<PageDisplayImage imgSrc={"/images/pages/Securite/securite6.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTopBottom" style={{ color: "white" }} >
					La cosmétovigilance
				</h1>
				<p style={{ color: "white" }} >
					C&apos;est une <span className="bold">surveillance</span> réalisée via une analyse des <span className="bold">effets indésirables rapportés par les consommateurs</span> et observés lors d&apos;une utilisation normale ou raisonnablement prévisible. Elle contribue ainsi à <span className="bold">l&apos;amélioration de la sécurité</span> des produits existants et à la formulation de nouveaux produits.
					<br /><br />
					<i>A noter : une réaction à un produit cosmétique n&apos;est pas forcément une allergie. En effet, dans 80% des cas ce sont des réactions d&apos;irritation.</i>
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Découvrez nos autres pôles</h2>
			</PageText>
		</>
	)
}
