import { useState, useEffect, useRef } from "react";
import { gsap } from "gsap";
import "../../style.css";

export default function Timeline({ state, currentVideoPartIndex, onSetCheckPosition, showVideo, showMenu, touchPointsettings, newX, onSeek, videoParts, duration, buffering }) {

    const timeline = useRef();
    const redTimeline = useRef();
    const whiteTimeline = useRef();
    const [mouseOver, setMouseOver] = useState(false);
    const [checkPosition, setCheckPosition] = useState(true);
    const [show, setShow] = useState(false);

    //Sets the timeline to the beginning if the player is already loaded
    useEffect(() => {
        changePosition(0);
        setShow(true);
    }, [])

    //When the player is loaded and the duration of the video known, creates the gsap animation for the timeline moving.
    useEffect(() => {
        if (duration !== 0) {
            gsap.set(timeline.current, { x: 0, ease: "none" });
            gsap.set("#timelineSeek", { x: 0, ease: "none" });
            state.playing ? playAnim() : pauseAnim(0);
        }
    }, [duration]);

    // If the video is playing but buffering, pause it
    useEffect(() => {
        if (duration === 0) return;
        (state.playing && !buffering) ? playAnim() : pauseAnim();
    }, [state.playing, buffering]);

    //Timeline animation when touch point is showing
    useEffect(() => {
        if (touchPointsettings.show && state.playing) {
            gsap.killTweensOf(redTimeline.current);
            gsap.killTweensOf(whiteTimeline.current);
            gsap.to(redTimeline.current, {
                x: -window.innerWidth * touchPointsettings.time / duration,
                ease: "none",
                duration: touchPointsettings.time - 0.2,
                onComplete: () => {
                    gsap.to(redTimeline.current, { x: 0, ease: "none", duration: 0.2 });
                }
            });
            gsap.to(whiteTimeline.current, {
                x: window.innerWidth * (touchPointsettings.time - 1) / duration,
                ease: "power2.inOut",
                duration: 1,
                onComplete: () => {
                    gsap.to(whiteTimeline.current, { x: 0, ease: "none", duration: touchPointsettings.time - 1 });
                }
            });
        }
    }, [touchPointsettings.show]);

    //If the user exits the menu and chose a new part to watch, the timeline moves accordingly
    useEffect(() => {
        gsap.set("#timelineSeek", { x: newX - timeline.current.getBoundingClientRect().x, ease: "none" });
    }, [newX])

    //Changes color of the section marks according to the progress of the timeline
    useEffect(() => {
        if (mouseOver) changeSectionColor(document.querySelector("#videoPartsSections_" + currentVideoPartIndex).getBoundingClientRect().x);
    }, [currentVideoPartIndex])

    //Hover animation for the timeline and the sections mark
    useEffect(() => {
        gsap.killTweensOf("#timelineSeek", "height");
        gsap.killTweensOf("#videoPartsLabel");
        gsap.killTweensOf("#here");
        gsap.killTweensOf("#dots");
        for (let i = 0; i < videoParts.length; i++) gsap.killTweensOf("#videoPartsSections_" + i);

        if (mouseOver) {
            gsap.to("#timelineSeek", { height: "100%", ease: "power1.inOut", duration: 0.2 });
            gsap.to("#videoPartsLabel", { opacity: 1, ease: "power1.inOut", duration: 0.2 });
            gsap.to("#here", { bottom: 27, ease: "power1.inOut", duration: 0.2 });
            gsap.to("#dots", { bottom: 9.5, ease: "power1.inOut", duration: 0.2 });

            changeSectionColor(document.querySelector("#here").getBoundingClientRect().x);
            document.querySelector("#timeline-wrapper").style.cursor = "pointer";
        } else {
            gsap.to("#timelineSeek", { height: "40%", ease: "power1.inOut", duration: 0.2 });
            gsap.to("#videoPartsLabel", { opacity: 0, ease: "power1.inOut", duration: 0.2 });
            gsap.to("#here", { bottom: 12, ease: "power1.inOut", duration: 0.2 });
            gsap.to("#dots", { bottom: 2, ease: "power1.inOut", duration: 0.2 });

            for (let i = 0; i < videoParts.length; i++) gsap.to("#videoPartsSections_" + i, { backgroundColor: "#BA0C2F", ease: "power1.inOut", duration: 0.2 });
        }
    }, [mouseOver])

    //Hides the timeline if the menu of a blog page is show on top
    useEffect(() => {
        gsap.killTweensOf("#timeline-wrapper");
        if (!showVideo || showMenu || !show) {
            gsap.to("#timeline-wrapper", { y: 100, ease: "power1.inOut", duration: !show ? 0 : 0.3 });
        } else {
            gsap.to("#timeline-wrapper", { y: 0, ease: "power1.inOut", duration: 0.5 });
        }
    }, [showVideo, showMenu, show])


    //Everytime the state.played is updated, check if the timeline is at the right place. This is a security to prevent buffering bugs
    useEffect(() => {
        if (!touchPointsettings.show && checkPosition) {
            var position = state.played * window.innerWidth;
            changePosition(position);
        }
    }, [state.played]);

    // After a new position is seeked, disable the position checking for 3 seconds, while the state.played gets up to date.
    useEffect(() => {
        onSetCheckPosition(checkPosition)
        if (!checkPosition) {
            const timeout = setTimeout(() => { setCheckPosition(true) }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [checkPosition]);

    //GSAP play and pause timeline animation
    const playAnim = () => {  gsap.to(timeline.current, { x: +window.innerWidth, ease: "none", duration: duration }) };
    const pauseAnim = () => { gsap.killTweensOf(timeline.current) };

    //Calculates the new position of the timeline when it's clicked
    const handleSeekMouseDown = event => {
        var newPosition = Math.min(event.clientX, window.innerWidth * 0.995);
        changePosition(newPosition);
        var seekPos = parseFloat(newPosition / window.innerWidth);
        onSeek(seekPos);
        changeSectionColor(newPosition);

        setCheckPosition(false);
    }

    //Change position of the timeline
    const changePosition = (position) => {
        gsap.killTweensOf(redTimeline.current);
        gsap.killTweensOf(whiteTimeline.current);
        gsap.to(redTimeline.current, { x: 0, ease: "none", duration: 0.1 });
        gsap.to(whiteTimeline.current, { x: 0, ease: "none", duration: 0.1 });
        gsap.killTweensOf("#timelineSeek", "x");

        gsap.to("#timelineSeek", { x: position - timeline.current.getBoundingClientRect().x, ease: "power1.inOut", duration: 0.3 });
    }

    //Change colors of the sections marks according to the progress of the timeline
    const changeSectionColor = (x) => {
        for (let i = 0; i < videoParts.length; i++) {
            if (document.querySelector("#videoPartsSections_" + i).getBoundingClientRect().x <= x) {
                gsap.to("#videoPartsSections_" + i, { backgroundColor: "#ECE6E0", ease: "power1.inOut", duration: 0.2 });
            } else {
                gsap.to("#videoPartsSections_" + i, { backgroundColor: "#BA0C2F", ease: "power1.inOut", duration: 0.2 });
            }
        }
    }

    return (
        <div
            id="timeline-wrapper"
            onMouseDown={handleSeekMouseDown}
            onMouseEnter={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
        >
            <div id="timeline" ref={timeline} >
                <div id="timelineSeek" >
                    <div ref={redTimeline} style={{ backgroundColor: "#BA0C2F" }} />
                    <div id="cursor" >
                        <img id={"here"} src="/images/general/here.png" />
                        <img id={"dots"} src="/images/general/timelineDots.png" />
                    </div>
                    <div ref={whiteTimeline} style={{ backgroundColor: "#ECE6E0" }} />
                </div>
            </div>

            {videoParts.map((value, index) =>
                <div
                    id={"videoParts"}
                    key={index}
                    style={{
                        left: `Calc(${(value.startInSec * 100 / duration)}vw - 0px)`,
                        visibility: `${index === 0 ? "hidden" : "visible"}`
                    }}
                >
                    <h5 id={"videoPartsLabel"} > {value.name} </h5>
                    <div id={"videoPartsSections_" + index} />
                </div>
            )}
        </div>
    );

}
