import { useState, useEffect } from "react";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
//import video from "../../../videoPaths.js"
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function DeveloppementPackaging() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	return (
		<>
			<PageTitle>Packaging Design <br /> Department</PageTitle>

			<PageDisplayImage isVideo={true} videoSrc={video["demoConceptionPackaging1"]} backgroundImage="none" imageSide="right">
				<p>
					The mission of the Packaging Design Department is to <span className="bold">design packaging that will protect and enhance Clarins products</span>.
					<br /><br />
					The design is carried out by Clarins project management engineers, specialists in <span className="bold">all packaging material transformation technologies</span>. Packaging design concepts are then developed in partnership with our suppliers.
					<br /><br />
					<span className="bold">Rigorous multi-criteria approval</span> is mandatory to ensure that packaging performance levels correspond to the <span className="bold">quality requirements we expect</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage isVideo={true} videoSrc={video["demoConceptionPackaging2"]} imageSide="center">
				<h1 className="paddingTopBottom">
					When the packaging development stage is complete
				</h1>
				<p>
					project managers finalize <span className="bold">specifications</span> that then become the <span className="bold">technical contract</span> covering all <span className="bold">aspects</span> which ensure <span className="bold">perfect control</span> from production by <span className="bold">packaging suppliers</span>, quality control upon reception, <span className="bold">filling and assembly</span> in our factories, to <span className="bold">use by end consumers</span>.
				</p>
			</PageDisplayImage>
		</>
	)
}