import { useState, useEffect } from "react";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
//import video from "../../../videoPaths.js"
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function DeveloppementPackaging() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	return (
		<>
			<PageTitle>Conception <br /> packaging</PageTitle>

			<PageDisplayImage isVideo={true} videoSrc={video["demoConceptionPackaging1"]} backgroundImage="none" imageSide="right">
				<p>
					La mission du service <span className="bold">conception packaging est de concevoir les packagings qui protègeront et sublimeront les produits Clarins</span>.
					<br /><br />
					La conception est assurée par les ingénieurs chefs de projet Clarins, <span className="bold">spécialistes</span> de toutes les <span className="bold">technologies de transformation des matériaux d&apos;emballage</span> puis les packagings sont développés en partenariat avec nos fournisseurs.
					<br /><br />
					Une <span className="bold">homologation multi critères rigoureuse</span> est réalisée  afin de garantir que le niveau de performance du packaging correspond bien <span className="bold">aux exigences de qualité attendues</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage isVideo={true} videoSrc={video["demoConceptionPackaging2"]} imageSide="center">
				<h1 className="paddingTopBottom">
					Quand la mise au point du packaging est terminée
				</h1>
				<p>
					Le chef de projet finalise la <span className="bold">spécification</span>, qui sera le <span className="bold">contrat technique</span> reprenant toutes les <span className="bold">caractéristiques</span> assurant une <span className="bold">parfaite maîtrise</span> depuis la <span className="bold">production</span> chez le <span className="bold">fournisseur de packaging</span>, le contrôle qualité à réception et le <span className="bold">conditionnement</span> dans nos usines et l&apos;<span className="bold">utilisation par la consommatrice</span>.
				</p>
			</PageDisplayImage>
		</>
	)
}