import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function PoleReglementation() {

	return (
		<>
			<PageTitle>Réglementation<br />& conformité</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Reglementation/reglementation1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					Le pôle réglementation générale et validation de la conformité (RG) est <span className="bold">garant de la veille et de la conformité réglementaire des matières premières, des formules et des produits finis</span>.
					<br /><br />
					<span className="bold">Les réglementations internationales sont diverses</span> et vont encadrer, interdire ou restreindre l&apos;utilisation de certains ingrédients, ainsi que certaines revendications.
					<br /><br />
					Les experts de ce pôle sont <span className="bold">garants de la validation réglementaire des formules</span> en prenant en compte leurs zones de commercialisation et la réglementation en vigueur, leur galénique, la cible consommateur, la zone d&apos;application et les revendications souhaitées.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imageSide="center" imgSrc={"/images/pages/Reglementation/reglementation2.png"} backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingTop" style={{ color: "white" }} >
					Ils sont également responsables de la <span className="bold">validation de tous les supports de communication</span> (emballages, site internet, brochures, réseaux sociaux…) et s&apos;assurent que les données de preuves soient conformes aux attendus.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Découvrez nos autres pôles</h2>
			</PageText>
		</>
	)
}
