import { useState, useEffect } from "react";
import Opening from "../Components/Intro/Opening.jsx";
import Entrance from "../Components/Intro/Entrance.jsx";
import "../style.css";
import IntroBackground from "../Components/Intro/IntroBackground.jsx";

export default function HomePage({ audio, onEntered, windowRatio, onPrepareVisit }) {

	const [entered, setEntered] = useState(false);
	const [endTransition, setEndTransition] = useState(false);
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(true);
	}, [])

	useEffect(() => {
		if (entered) onEntered();
	}, [entered]);

	const transitionToVisit = (path) => {
		onPrepareVisit(path);
		setShow(false);
	};

	return <>
		{!endTransition && < Opening onEnter={(newEntered) => { setEntered(newEntered) }} onEndTransition={(newEndTransition) => { setEndTransition(newEndTransition) }} />}

		{entered && < Entrance onLeaveIntro={(path) => transitionToVisit(path)} />}

		<IntroBackground
			show={show}
			soundOn={entered}
			windowRatio={windowRatio}
			audio={audio}
		/>
	</>
}
