import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Prospective() {

	return (
		<>
			<PageTitle>prospective</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Le laboratoire Prospective textures a plusieurs missions :</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective1.png"} imageSide="right">
				<h1 className="paddingTopBottom">
					Recherche de nouveaux excipients
				</h1>
				<p>
					•  Substitution des excipients existants par <span className="bold">des alternatives plus efficaces, plus vertes ou plus durables</span>.
					<br /><br />
					•  Nouveaux ingrédients pouvant permettre la <span className="bold">création de textures innovantes</span>.
					<br /><br />
					Chaque élément de leur dossier est évalué de façon rigoureuse pour s&apos;assurer qu&apos;ils sont bien <span className="bold">conformes à la politique ingrédients de notre Groupe</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective2.png"} backgroundImage="none" backgroundColor="transparent">
				<h1 className="paddingTopBottom">
					Travail préparatoire en amont du développement
				</h1>
				<p>
					Pour les projets les plus ambitieux de la marque, l&apos;équipe Prospective <span className="bold">effectue des essais de châssis</span> qui serviront de base de travail pour les équipes de développement.
					<br /><br />
					Elle commence à architecturer les produits autour des actifs-phares, si déjà connus à ce moment-là.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective3.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F">
				<h1 className="paddingTopBottom" style={{ color: "white" }} >
					Création de formules originales
				</h1>
				<p style={{ color: "white" }} >
					Pour ces projets, pas de consignes spécifiques afin de permettre <span className="bold">une plus grande liberté créative</span> et favoriser l&apos;émergence de concepts totalement innovants qui pourront <span className="bold">inspirer au marketing de futurs briefs projets</span>.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Découvrez nos autres laboratoires de formulation</h2>
			</PageText>
		</>
	)
}
