import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function SourcingResponsable() {

	return (
		<>
			<PageTitle>Sourcing <br />Responsable</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Pour la sélection de tout ingrédient, et notamment de nos ingrédients actifs,</span></span>
					<br />
					<span><span className="second">nous respectons notre <span className="bold">charte interne de sourcing responsable</span>.</span></span>
				</p>
			</PageText>

			<div className="page-block" style={{ position: "absolute", padding: 0, top: "20vh", right: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/SourcingResponsable/sourcingResponsable4.png" style={{ width: "100%" }} />
			</div>

			<PageText >
				<div className="padding sourcingResponsable1">
					<h1 className="paddingTopBottom">
						3 catégories d'actifs et d'excipients
					</h1>
					<ul className="padding">
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable1.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>Plantes</p>
							<h4>Extraits de plantes, huiles, beurres, cires, huiles essentielles</h4>
						</li>
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable2.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>Dérivés du naturel</p>
							<h4>Excipients dérivés du naturel, ingrédients obtenus par biotechnologie ou solvant non agro-sourcé</h4>
						</li>
						<li>
							<div className="listImageSourcing" style={{ backgroundImage: `url("/images/pages/SourcingResponsable/sourcingResponsable3.png")` }} />
							<p style={{ textAlign: "center", fontWeight: "bold" }}>Ingrédient de synthèse</p>
							<h4>Peptides, molécules actives de synthèse, filtres solaires organiques, …</h4>
						</li>
					</ul>
				</div>
			</PageText>

			<PageText backgroundImage={"linear-gradient(#eee8e2, #fff)"} >
				<h1 className="paddingBottom" style={{ width: "100%", color: "#BA0C2F" }}>
					UN NIVEAU PRE-REQUIS ET DEUX NIVEAUX <br /> DE LABELLISATION POUR LES PLANTES
				</h1>
				<div
					className="fullSizeImage"
					style={{
						backgroundImage: `url("./images/pages/SourcingResponsable/sourcingResponsable5.png")`
					}}
				/>
			</PageText >

		</>
	)
}
