import { useState, useEffect, useRef } from "react";

import "../../style.css";
import "./pageStyle.css";
import MenuButton from "../General/MenuButton";

import { useLanguage } from '../../Contexts/LanguageContext.jsx';

export default function MenuLang() {
	const [menuOpened, setMenuOpened] = useState(false);
	const { currentLanguage, setCurrentLanguage } = useLanguage();
	const [currentLang, setCurrentLang] = useState(currentLanguage);
	const wrapperRef = useRef(null);

	const toggleMenu = () => {
		setMenuOpened(!menuOpened);
	}

	const handleOutsideClick = (event) => {
		if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
			setMenuOpened(false);
		}
	};

	useEffect(() => {
		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, []);

	return (
		<div
			id="menuLang"
			ref={wrapperRef}
			style={{
				position: "fixed",
				top: "auto",
				left: 50,
				bottom: 35,
				width: 50,
				zIndex: 999,
				display: 'block',
				color: '#FFFFFF',
				textTransform: 'uppercase',
				fontSize: 16,
				transition: 'bottom 0.2s ease-out'
			}}
		>
			<div
				onClick={toggleMenu}
				style={{
					cursor: menuOpened ? 'default' : 'pointer',
				}}
			>
				{currentLang}
				<span
					style={{
						marginLeft: 10,
						cursor: 'pointer'
					}}
				>
					{menuOpened ? (
						<svg fill="#FFFFFF" height="12px" width="12px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="-33 -33 396.00 396.00" xmlSpace="preserve" stroke="#000000" strokeWidth="0.0033" transform="rotate(0)matrix(1, 0, 0, -1, 0, 0)"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path> </g></svg>
					) : (
						<svg fill="#FFFFFF" height="12px" width="12px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="-33 -33 396.00 396.00" xmlSpace="preserve" stroke="#000000" strokeWidth="0.0033" transform="rotate(0)"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path id="XMLID_225_" d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393 c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393 s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"></path> </g></svg>
					)}
				</span>
			</div>
			<div
				onClick={() => {
					setCurrentLang(currentLang == 'fr' ? 'en' : 'fr')
					setCurrentLanguage(currentLang == 'fr' ? 'en' : 'fr')
					setMenuOpened(false)
				}}
				style={{
					display: menuOpened ? 'inline-block' : 'none',
					marginTop: 10,
					cursor: 'pointer'
				}}
			>
				{currentLang == 'fr' ? 'EN' : 'FR'}
			</div>
		</div>
	)
}
