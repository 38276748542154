import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Conditionnement() {

	return (
		<>
			<PageTitle>Conditionnement</PageTitle>

			<PageText style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingBottom" style={{ width: "100%", color: "#BA0C2F", textAlign: "left", textTransform: "uppercase" }}>
					Comment se déroule le conditionnement des produits ?
				</h1>
				<p>
					<span className="bold">Plus de 200 personnes</span> : conditionneurs, manutentionnaires, techniciens de maintenance, encadrement, travaillent <span className="bold">24h/24</span>, sur <span className="bold">3 équipes</span> dont une de nuit réduite.
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement1.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					Le conditionnement réalise l&apos;assemblage des produits finis
				</h1>
				<p className="paddingTop">
					Tubes, flacons, pots à partir d&apos;un vrac provenant de la fabrication et de différents articles de conditionnement.
					<br /><br />
					Les produits finis, mis en caisse, sont regroupés sur des palettes à destination d&apos;Amiens, notre centre de logistique.
					<br /><br />
					<span className="bold">Les 25 lignes de production</span> sont dédiées à des types de packaging tels que les tubes, échantillons, pots ou flacons allant de 3mL à 400mL ; une ligne tube ne fait que des tubes, une ligne lotions ne fait que des lotions.
					<br /><br />
					Seules quelques lignes sont capables de faire deux types de packaging, mais toutes font plusieurs formats.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement2.png"} >
				<h1 className="paddingTop">
					Les lignes sont de plus en plus robotisées et équipées de système de contrôle par vision
				</h1>
				<p className="paddingTop" >
					La mission principale des opérateurs est de superviser le fonctionnement des lignes, de les approvisionner et de contrôler les produits fabriqués selon l&apos;utilisation qu&apos;en fera notre future cliente.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement3.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					Le nombre de références différentes à produire étant important…
				</h1>
				<p className="paddingTop">
					<span className="bold">... près de 3000</span>, les changements d&apos;un produit à l&apos;autre sont très fréquents.
					<br /><br />
					<span className="bold">A la fin d&apos;une production</span>, tous les éléments d&apos;outillage qui sont en contact avec la crème ou les lotions sont <span className="bold">démontés</span> pour être <span className="bold">nettoyés</span>.
					<br /><br />
					De plus, tous les réglages des machines doivent être modifiés pour être en mesure de produire le prochain produit fini. Il est donc très rare qu&apos;une même production (changement de lot) dure plus d&apos;une journée.
				</p>
			</PageDisplayImage>
		</>
	)
}
