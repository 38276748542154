import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Prospective() {

	return (
		<>
			<PageTitle>PROSPECTIVE</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">​This is a specialized laboratory with several missions:</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective1.png"} imageSide="right">
				<h1 className="paddingTopBottom">
					NEW EXCIPIENT RESEARCH :
				</h1>
				<p>
					•  <span className="bold">More effective, greener or more sustainable alternatives</span> to existing excipients
					<br /><br />
					•  New ingredients that can be used to <span className="bold">create innovative textures</span>.
					<br /><br />
					Each element of their dossier is thoroughly assessed to ensure that they <span className="bold">comply with CLARINS ingredients policy</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective2.png"} backgroundImage="none" backgroundColor="transparent">
				<h1 className="paddingTopBottom">
					PREPARATORY WORK PRIOR TO DEVELOPMENT :
				</h1>
				<p>
					For the brands’ most ambitious projects, the Prospective team <span className="bold">carries out chassis tests</span> that will serve as a working basis for the development teams or begin to build the product around flagship assets if already selected.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Prospective/prospective3.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F">
				<h1 className="paddingTopBottom" style={{ color: "white" }} >
					CREATING ORIGINAL FORMULAS :
				</h1>
				<p style={{ color: "white" }} >
					There are no specific instructions for these projects to allow <span className="bold">greater creative freedom</span> and encourage totally innovative concepts that may <span className="bold">inspire future marketing briefs</span>.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }}>DISCOVER OUR OTHER FORMULATION LABORATORIES</h2>
			</PageText>
		</>
	)
}
