import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function PoleEvaluation() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	return (
		<>
			<PageTitle>SECURITY <br />& MONITORING</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Securite/securite1.png"} backgroundImage="none" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					SECURITY ASSESSMENT
				</h1>
				<p className="paddingTop">
					The safety assessment and monitoring department carries out toxicological assessments of all the raw materials and active ingredients used in our formulas, This department also checks compliance with various regulations at the time of listing and throughout product life.
					<br /><br />
					A <span className="bold">risk assessment</span> is carried out i.e. the probability of a hazard arising from the use of a particular ingredient or product.
					<br /><br />
					Unlike a pharmaceutical product, a cosmetic product must represent <span className="bold">zero risk for human health.</span>
				</p>
			</PageDisplayImage >

			<PageText>
				<ul className="padding" >
					<li style={{ position: 'relative' }}>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite2_en.png")` }} />
						<div style={{ position: 'absolute', top: '50%', right: '-45px', fontSize: '28px', color: '#c2001a' }}>X</div>
					</li>
					<li style={{ position: 'relative' }}>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite3_en.png")` }} />
						<div style={{ position: 'absolute', top: '50%', right: '-45px', fontSize: '28px', color: '#c2001a' }}>=</div>
					</li>
					<li>
						<div className="listImage" style={{ backgroundImage: `url("/images/pages/Securite/securite4_en.png")` }} />
					</li>
				</ul>
			</PageText>

			<PageText backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingBottom" style={{ width: "100%" }}>
					The department also guarantees the safety of finished products (new and existing) through <span className="bold">the toxicological validation of formulas</span> and the drafting of <span className="bold">safety evaluation reports</span> (SER).
				</p>

				<div
					className="fullSizeImage"
					style={{ backgroundImage: `url("./images/pages/Securite/securite5_en.png")` }}
				/>

			</PageText >

			<PageDisplayImage imgSrc={"/images/pages/Securite/securite6.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTopBottom" style={{ color: "white" }} >
					COSMETOVIGILANCE
				</h1>
				<p style={{ color: "white" }} >
					This monitoring is carried out by analyzing undesirable effects observed during normal or reasonably foreseeable use, and thus contributes <span className="bold">to improving the safety of existing products</span> and formulating new ones.
					<br /><br />
					<i>Please note: a reaction to a cosmetic product is not necessarily an allergy. In 80% of cases, it is an irritation.</i>
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Discover our other centers</h2>
			</PageText>
		</>
	)
}
