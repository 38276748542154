import { useState, useEffect, useMemo } from "react";

import "../style.css";
import Visit from "../Components/Visit/Visit.jsx"
//import video from "../videoPaths.js"

import { useLanguage } from '../Contexts/LanguageContext.jsx';

export default function Usine({ windowRatio, soundOn, onSoundClick }) {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	const videoPartsUsineRef = currentLanguage === 'fr'
		? import('/videoParts-usine-fr.js')
		: import('/videoParts-usine-en.js');

	const [videoPartsUsine, setVideoPartsUsine] = useState([]);

	videoPartsUsineRef.then((videoPartsUsineEl) => {
		setVideoPartsUsine(videoPartsUsineEl.default);
	});

	return <>
		<Visit
			windowRatio={windowRatio}
			soundOn={soundOn}
			path={"/usine"}
			videoParts={videoPartsUsine}
			videoPath={video.usine}
			onSoundClick={(soundOn) => onSoundClick(soundOn)}
		/>
	</>
}
