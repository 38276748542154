import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function LaboRmc() {

	return (
		<>
			<PageTitle>Raw <br /> Material Center</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc1.png"} backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
				<p className="paddingTopBottom">
					Pour relever les défis de l&apos;innovation permanente chez Clarins, <span className="bold">nos laboratoires de formulation sélectionnent de nouvelles matières premières</span> (actifs, excipients, conservateurs et parfums), pour <span className="bold">améliorer les performances, l&apos;efficacité ou la sensorialité</span> de nos produits.
					<br /><br />
					Des critères stricts, tels que l&apos;origine, la naturalité et la conformité aux réglementations, guident nos choix.
				</p>
				<div id="displayImageHighlight" className="marginTopBottom first" >
					<div className="paddingTopBottom">
						<p>
							Le laboratoire RMC (Raw Material Center) est au cœur de cette activité avec plusieurs missions :
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc2.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
				<h1 className="paddingTop">
					L&apos;étude des changements sur nos matières premières
				</h1>
				<p className="paddingTopBottom">
					Tout changement de fournisseur, de site de production et de qualité doit être <span className="bold">étudié et validé par nos experts en R&D</span>.
				</p>

				<h1 className="paddingTop">
					Pré-formulation d&apos;actifs et de conservateurs
				</h1>
				<p className="paddingTop">
					L&apos;évaluation préalable des nouvelles matières premières permet l&apos;<span className="bold">identification précoce des instabilités et d&apos;incompatibilités</span> ce qui permet d&apos;économiser du temps lors du développement.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Rmc/rmc3.png"}>
				<>
					<h1 className="paddingTop">
						Sécurisation des approvisionnements
					</h1>
					<p className="paddingTopBottom">
						<span className="bold">L&apos;homologation de secondes sources</span>, de nouveaux fournisseurs sont nécessaires pour sécuriser les approvisionnements en cas de rupture chez nos fournisseurs habituels.
					</p>

					<h1 className="paddingTop">
						Bases de données MP
					</h1>
					<p className="paddingTop">
						<span className="bold">La maîtrise de nos outils de gestion de données</span>, de leur exactitude et de leur fiabilité, est un enjeu en raison des nouvelles contraintes réglementaires et du volume de matières premières associé.
					</p>
				</>
			</PageDisplayImage>
		</>
	)
}
