import { useState, useEffect } from "react";
import { useNavigate, redirect } from "react-router-dom";
import "../../style.css";
import { useLanguage } from '../../Contexts/LanguageContext.jsx';

export default function Entrance({ onLeaveIntro }) {

	const [show, setShow] = useState(false);
	const [isMobile, setIsMobile] = useState(false);

	const navigate = useNavigate();
	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const userAgent = navigator.userAgent;

	const mobileRegex = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
	const isMobileDevice = mobileRegex.test(userAgent);

	useEffect(() => {
		if (isMobileDevice) {
			setIsMobile(true);
		}
	}, [isMobileDevice]);

	const lineBreak = <br />;

	useEffect(() => {
		setTimeout(() => setShow(true), 200);
	}, [])

	const visit = (path) => {
		setShow(false);

		if (isMobile) {
			setTimeout(() => window.location.href = path, 500);
		} else {
			onLeaveIntro(path);
			setTimeout(() => navigate(path), 500);
		}
	}

	return <>

		<div id="videoRedColor" className={show ? "show" : "hide"} />

		<section id="entrance" className={show ? "show" : "hide"} >
			<h1>
				{currentLanguage === 'fr' ? (
					<>
						À la découverte des{lineBreak} laboratoires et usine
					</>
				) : (
					<>
						Discover Clarins{lineBreak} Laboratories & Factory
					</>
				)}
			</h1>

			<p className={"padding"} >
				{currentLanguage === 'fr' ? (
					<>
						Une visite guidée et interactive au cœur de 70 ans d&apos;innovation.{lineBreak}
						Venez découvrir les experts passionnés et le savoir-faire des équipes Clarins.
					</>
				) : (
					<>
						An interactive, guided tour through 70 years of innovation.{lineBreak}
						Discover expert passion and « savoir-faire » at the heart of Clarins.
					</>
				)}
			</p>

			<div className="flex">
				<button className="bubble" onClick={() => visit("/laboratoires")}>
					{currentLanguage === 'fr' ? (
						"Visite des laboratoires"
					) : (
						"Visit the laboratories"
					)}
				</button>
				<button className="bubble" onClick={() => visit("/usine")}>
					{currentLanguage === 'fr' ? (
						"Visite de l’usine"
					) : (
						"Visit the factory"
					)}
				</button>
			</div>
		</section>
	</>
}
