import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import IntroBackground from "../Components/Intro/IntroBackground";
import "../style.css"
import { useLanguage } from '../Contexts/LanguageContext.jsx';

export default function PageNotFound({ windowRatio, audio, soundOn }) {

	const navigate = useNavigate()
	const { currentLanguage, setCurrentLanguage } = useLanguage()

	//Hover animations on circles
	const animation = () => {
		gsap.killTweensOf("#circle-one");
		gsap.killTweensOf("#circle-two");
		gsap.killTweensOf("#circle-three");
		gsap.killTweensOf("#circle-four");
		const dur = 0.3;

		gsap.to("#circle-one", { scale: 1.2, ease: "power1.inOut", delay: 0, duration: dur });
		gsap.to("#circle-one", { scale: 1, ease: "elastic.out", delay: dur, duration: 5 });

		gsap.to("#circle-two", { scale: 1.15, ease: "power1.inOut", delay: 0.1, duration: dur });
		gsap.to("#circle-two", { scale: 1, ease: "elastic.out", delay: dur + 0.1, duration: 5 });

		gsap.to("#circle-three", { scale: 1.15, ease: "power1.inOut", delay: 0.2, duration: dur });
		gsap.to("#circle-three", { scale: 1, ease: "elastic.out", delay: dur + 0.2, duration: 5 });

		gsap.to("#circle-four", { scale: 1.15, ease: "power1.inOut", delay: 0.3, duration: dur });
		gsap.to("#circle-four", { scale: 1, ease: "elastic.out", delay: dur + 0.3, duration: 5 });
	}

	return <>
		<section id="opening" >
			<div id="circle-one" className="circle" onMouseEnter={() => animation()} />
			<div id="circle-two" className="circle" />
			<div id="circle-three" className="circle" />
			<div id="circle-four" className="circle" />

			<button
				className="icon"
				id="circle-center"
				onClick={() => navigate("/")}
			>
				<h1 style={{ color: "white" }} >404</h1>
			</button>

			<p id="circle-text">
				{currentLanguage === 'fr' ? "Cliquez sur le 404 pour retourner sur l'expérience" : "Click on the 404 to return to the experience"}
			</p>
		</section>

		<div id="videoGreyColor" />

		<IntroBackground
			show={true}
			entered={true}
			windowRatio={windowRatio}
			audio={audio}
			soundOn={soundOn}
		/>
	</>
}
