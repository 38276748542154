import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { gsap } from "gsap";
import { useLanguage } from '../../Contexts/LanguageContext.jsx';
import MenuLang from "../Pages/MenuLang.jsx";

export default function Menu({ videoParts, onCloseMenu, path, closeMenu, currentVideoPartIndex, windowRatio }) {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const [menuVideoPartIndex, setMenuVideoPartIndex] = useState(currentVideoPartIndex);
	const [chapterTitle, setChapterTitle] = useState();

	const navigate = useNavigate();

	const svg = useRef();
	const rect = useRef();
	const title = useRef();
	const subtitle = useRef();

	useEffect(() => { gsap.to(".fade-in", { opacity: 1, ease: "none", delay: 0.8, duration: 1.5 }) }, []);

	//Set up of the svg overlay
	useEffect(() => {
		var h = window.innerHeight * 100 / window.innerWidth;
		gsap.set(svg.current, { attr: { viewBox: `0 0 100 ${h}` } });
		gsap.set(rect.current, { attr: { height: `${h}` } });
	}, [windowRatio]);

	//Title, subtitle and touch points buttons animations when a video part is clicked
	useEffect(() => {
		for (let i = 0; i < videoParts[menuVideoPartIndex].touchPoints.length; i++) {
			gsap.set("#button_" + i, {
				opacity: 0, ease: "none", onComplete: () => {
					gsap.to("#button_" + i, { opacity: 1, ease: "none", delay: 1 + 0.2 * i, duration: 0.5 });
				}
			})
		}

		gsap.to(subtitle.current, { y: "-100%", ease: "power2.inOut", delay: chapterTitle ? 0 : 0.5, duration: chapterTitle ? 0.5 : 0 });
		gsap.to(title.current, {
			y: "-100%", ease: "power2.inOut", delay: chapterTitle ? 0 : 0.5, duration: chapterTitle ? 0.5 : 0, onComplete: () => {
				setChapterTitle(videoParts[menuVideoPartIndex].name);
				gsap.set(title.current, {
					y: "100%", ease: "none", onComplete: () => {
						gsap.to(title.current, { y: 0, ease: "power1.inOut", duration: 0.8 });
					}
				})

				gsap.set(subtitle.current, {
					y: "100%", ease: "none", onComplete: () => {
						gsap.to(subtitle.current, { y: 0, ease: "power1.inOut", delay: 0.5, duration: 0.8 });
					}
				})
			}
		})
	}, [menuVideoPartIndex]);

	useEffect(() => {
		if (closeMenu) handleCloseMenu(false);
	}, [closeMenu]);


	// When the menu is closed, the svg animation is launched and the menu content is faded out
	// If the visitor choses to change the video part to watch (changePart = true), the players seeks to the new part,
	// If the visitor clicked on a touch point (newPath), we navigate to the new path after the animation
	const handleCloseMenu = (changePart = false, newPath = undefined) => {
		var animation = document.getElementById("closeMenuAnim");
		animation.beginElement();

		gsap.to("#menu-content", { opacity: 0, ease: "none", duration: 0.3 });
		for (let i = 0; i < videoParts[menuVideoPartIndex].touchPoints.length; i++) {
			gsap.to("#button_" + i, { opacity: 0, ease: "none", duration: 0.3 });
		}
		if (changePart && (menuVideoPartIndex != currentVideoPartIndex)) {
			setTimeout(() => onCloseMenu(videoParts[menuVideoPartIndex].startInSec), 1000);
		} else if (newPath) {
			setTimeout(() => onCloseMenu(), 1500);
			setTimeout(() => navigate(newPath), 1000);
		}
		else {
			setTimeout(() => onCloseMenu(), 1000);
		}
		if (newPath) setTimeout(() => navigate(newPath), 1000);
	}

	//Hover CTA on the touch point buttons
	const handleButtonMouseEnter = (file) => {
		gsap.to("#buttonCenter_" + file, { backgroundColor: "#ECE6E0", ease: "none", duration: 0.2 });
		gsap.to("#buttonLabel_" + file, { opacity: 1, ease: "none", duration: 0.2 });
	}
	const handleButtonMouseLeave = (file) => {
		gsap.to("#buttonCenter_" + file, { backgroundColor: "transparent", ease: "none", duration: 0.2 });
		gsap.to("#buttonLabel_" + file, { opacity: 0, ease: "none", duration: 0.2 });
	}


	return (<>
		<section id="menu-svg-wrapper">
			<svg ref={svg} viewBox="0 0 100 100" width="100%"  >
				<defs>
					<mask id="mask" x="0" y="0" width="100" height="100">
						<rect x="0" y="0" width="100%" height="100%" fill="#fff" />
						<circle
							id="menu-circle"
							cx={(window.innerWidth - 55) * 100 / window.innerWidth}
							cy={55 * (window.innerHeight * 100 / window.innerWidth) / window.innerHeight}
						>
							<animate
								attributeName="r" begin="0s" dur="1s" repeatCount="1" fill="freeze"
								values={windowRatio > 0.7 ? "200;130;70;30;15;9;10" : "300;260;140;60;30;18;20"}
							/>
							<animate
								id="closeMenuAnim"
								attributeName="r" begin="indefinite" dur="0.6s" repeatCount="1" fill="freeze"
								values={windowRatio > 0.7 ? "10;200" : "20;300"}
							/>
						</circle>
					</mask>
				</defs>
				<rect ref={rect} x="0" y="0" width="100" height="100" mask="url(#mask)" fillOpacity="1" fill="#BA0C2F" />
			</svg>
		</section>

		<section id="menu-content" >
			<div id="menu-chapters" className="flex fade-in" >
				{videoParts.map((value, index) =>
					<button
						className="underline"
						key={index}
						style={{ opacity: index === menuVideoPartIndex ? 1 : 0.5 }}
						onClick={() => setMenuVideoPartIndex(index)}
					>
						<h4>{value.name} </h4>
					</button>
				)}
			</div>

			<div id="menu-center" >
				<div id="menu-here" className="fade-in">
					<img src="/images/general/here.png" />
				</div>
				<div>
					<h1 id="menu-chapter-title" ref={title} >{chapterTitle}</h1>
				</div>
				<div>
					<button ref={subtitle} className="no-effect" onClick={() => handleCloseMenu(true)}>
						{currentLanguage === 'fr' ?
							(chapterTitle === videoParts[currentVideoPartIndex].name ? `Continuer d'explorer` : "Explorer ce chapitre") :
							(chapterTitle === videoParts[currentVideoPartIndex].name ? 'Continue the visit' : 'Explore this chapter')}
					</button>
				</div>
			</div>

			<div id="menu-timeline-wrapper" className="timeline fade-in">
				<div>
					<div id="menu-touchpoints">
						{videoParts[menuVideoPartIndex].touchPoints.map((value, index) =>
							<div key={index} id={"button_" + index}>
								<h4 id={"buttonLabel_" + value.file} className="buttonLabel">
									{value.name}
								</h4>

								<button
									className="timeline"
									onClick={() => handleCloseMenu(false, value.path)}
									onMouseEnter={() => handleButtonMouseEnter(value.file)}
									onMouseLeave={() => handleButtonMouseLeave(value.file)}
								>
									<div id={"buttonCenter_" + value.file} />
								</button>
							</div>
						)}
					</div>
					<div id="menu-timeline" className="timeline menu" />
				</div>
				<div id="menu-footer" className="flex" >
					<MenuLang />

					<button
						className="underline"
						onClick={() => navigate("/")}
					>
						<p>
							{currentLanguage === 'fr' ? (
								"Retour à l'accueil"
							) : (
								"Back to home page"
							)}
						</p>
					</button>
					<button
						className="underline"
						onClick={() => path === "/usine" ? navigate("/laboratoires") : navigate("/usine")}
					>
						<p>
							{currentLanguage === 'fr' ?
								(path === "/usine" ? "Visite des laboratoires" : "Visite de l'usine") :
								(path === "/usine" ? "Visit the laboratories" : "Visit the factory")}
						</p>
					</button>
				</div>
			</div>
		</section>
	</>
	)
}
