import { useEffect, useState, useRef } from "react";
import { gsap } from "gsap";
import "../../style.css";
import "./menuButtonStyle.css";

export default function MenuButton({z, showVideo = true, showMenu = true, onMenuClick, animation, style}) {

    const [showMenuIcon, setShowMenuIcon] = useState(true);
    const [loading, setLoading] = useState(false);
    const icon = useRef();

    //show loading animation when mounted
    useEffect(() => {
        setLoading(true);
    }, []);
    useEffect(() => {
        if (loading) {
            const timeout = setTimeout(() => setLoading(false), 3500);
            return () => clearTimeout(timeout);
        }
    }, [loading]);
    
    //switch animation between exit and menu icons
    useEffect(() => {
        if (animation) {
            gsap.to(icon.current, { y: "-100%", ease: "power2.inOut" , duration: 0.2, onComplete: () => {
                setShowMenuIcon(showMenu || !showVideo);
                gsap.set(icon.current, { y: "100%", ease: "none", onComplete: () => {
                    gsap.to(icon.current, { y: 0, ease: "power1.inOut", duration: 0.3});
                }})
            }})
        }
    }, [showMenu, showVideo]);

    return  (<>
        <div id="menu-button-wrapper"
            className={showVideo ? "show" : "hide"}                 
            style={style ? style :{zIndex: z}}
        >
            <button
                className={(loading) ? "touchPointClicked icon menu" : "hoverable icon menu"}
                onClick={() =>{ if (!loading) onMenuClick()}}
            >
                <div>
                    <img
                        ref={icon}
                        src={showMenuIcon ? "/images/general/exitMenuIcon.png" : "/images/general/menuIcon.png"}
                    />
                </div>

                {(loading) &&
                <svg>
                    <circle cx="50%" cy="50%" r="43%" />
                </svg>
                }
                
            </button>
        </div>
    </>
    )
}
