import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Soin() {

	return (
		<>
			<PageTitle>SKIN CARE</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">This laboratory develops <span className="bold">all our brands' face and body care products</span>,</span></span>
					<br />
					<span><span className="second">which means dozens of products launched every year and several</span></span>
					<br />
					<span><span className="third">hundred tests to perfect the ideal texture to satisfy our consumers.</span></span>
				</p>
			</PageText>

			<div className="page-block" style={{ position: "absolute", zIndex: 1, padding: 0, top: "20vh", right: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/Soin/soin2.png" style={{ width: "100%" }} />
			</div>

			<PageDisplayImage imgSrc={"/images/pages/Soin/soin1.png"}>
				<p className="paddingTopBottom">
					<span className="bold">Some products require special expertise</span>, such as brightening products, which must comply with specific regulatory conditions or foaming products.
				</p>
				<div id="displayImageHighlight">
					<div className="paddingTopBottom">
						<p>
							The main technical challenge facing our expert chemists is to <span className="bold">stabilize mixtures containing numerous active ingredients</span> and <span className="bold">to meet the ambitious eco-design targets</span> set by our brands without compromising on product sensoriality.
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<div className="page-block" style={{ padding: 0, zIndex: 1, position: "absolute", bottom: "20vh", left: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/Soin/soin3.png" style={{ width: "100%" }} />
			</div>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >DISCOVER MORE OF OUR FORMULATION LABORATORIES</h2>
			</PageText>
		</>
	)
}
