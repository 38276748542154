import PageText from "../../../Components/Pages/PageText.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";
import { useEffect, useState, useRef } from "react";

export default function Phytochimie() {

	const topRef = useRef()
	const [absoluteImageTop, setAbsoluteImageTop] = useState("20vw")
	useEffect(() => {
		setAbsoluteImageTop(String(topRef.current.getBoundingClientRect().top) + "px")
	}, [])

	return (
		<>
			<PageTitle>PHYTOCHEMISTRY &<br /> ACTIVE INGREDIENTS</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Plants have always been a <span className="bold">source of inspiration</span> for Clarins and remain at the <span className="bold">heart of our research</span>.</span></span>
					<br />
					<span><span className="second">Anthonin explains in a little more detail what goes on in his laboratory and how he <span className="bold">transforms plants into active ingredient extracts</span>.</span></span>
				</p>
			</PageText>

			<div id="phytochimieAbsoluteImages" className="page-block absoluteImage" style={{ top: `${absoluteImageTop}` }} >
				<img src="/images/pages/Phytochimie/phytochimie2.png" />
			</div>
			<div ref={topRef} />

			<PageVideo videoName={"itwGori"} style={{ marginBottom: '100px' }}>
				Anthonin Gori<br />PROJECT MANAGER – PHYTOCHEMISTRY
			</PageVideo>

			<PageDisplayImage imageSide="center" imgSrc={"/images/pages/Phytochimie/phytochimie1.png"} title="FOCUS ON EXTRACTION TECHNIQUES" style={{ paddingTop: '40px' }}>
				<p className="paddingTop">
					We use eco-responsible techniques such as <span className="bold">microwaves</span> and <span className="bold">supercritical CO2</span> to extract a wide spectrum of molecules using <span className="bold">renewable solvents</span> such as water or internal plant fluids.
				</p>
			</PageDisplayImage>
		</>
	)
}
