import { createContext, useContext, useState, useEffect } from 'react';

const LanguageContext = createContext();

export const LanguageProvider = ({ children }) => {
	const storedLanguage = localStorage.getItem('currentLanguage');
	const initialLanguage = storedLanguage ? storedLanguage : 'fr';

	const [currentLanguage, setCurrentLanguage] = useState(initialLanguage);

	useEffect(() => {
		localStorage.setItem('currentLanguage', currentLanguage);
	}, [currentLanguage]);

	return (
		<LanguageContext.Provider value={{ currentLanguage, setCurrentLanguage }}>
			{children}
		</LanguageContext.Provider>
	);
};

export const useLanguage = () => {
	return useContext(LanguageContext);
};