import { useState, useEffect } from "react";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
//import video from "../../../videoPaths.js"
import "../../../style.css";

import { useLanguage } from '../../../Contexts/LanguageContext.jsx';

export default function ControleQualite() {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const videoRef = currentLanguage === 'fr'
		? import('/videoPaths-fr.js')
		: import('/videoPaths-en.js');

	const [video, setVideo] = useState({});

	videoRef.then((videoEl) => {
		setVideo(videoEl.default);
	});

	return (
		<>
			<PageTitle>Contrôle <br /> qualité</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span>
						<span className="first">Aujourd&apos;hui, les normes qualité mondiales deviennent de plus en plus exigeantes et nous devons répondre aux différentes réglementations mondiales. Notre site est <b>inspecté</b> par les <span className="bold">autorités de chaque pays client</span>. D&apos;où l&apos;importance du département qualité, il est le <span className="bold">garant de la sécurité et de la conformité de nos produits</span>.</span>
					</span>
				</p>
			</PageText >

			<PageText>
				<h1 style={{ textTransform: "uppercase", width: "100%", color: "#BA0C2F", textAlign: "left" }}>
					Le laboratoire de contrôle intervient sur l&apos;ensemble des étapes du process de production...
				</h1>
			</PageText>

			<PageDisplayImage isVideo={true} videoSrc={video["demoControleQualite1"]} videoSize="small" backgroundImage="none" imageSide="right">
				<p>
					… Des Matières Premières et packagings, des crèmes fabriquées, et des produits finis.
					<br /><br />
					Chaque référence de <b>produit</b> possède sa <b>fiche spécifique de contrôle</b> où figure l&apos;ensemble des tests à réaliser tels que les tests organoleptiques, physico-chimiques, microbiologiques ou d&apos;usage…
					<br /><br />
					La <b>libération</b> des produits sera réalisée <b>après vérification de l&apos;ensemble des tests définis par les exigences réglementaires mais également par nos exigences Clarins.</b>
					<br />
					Mais le contrôle n&apos;est qu&apos;une petite partie de ce qui permet de maîtriser la qualité des produits chez Clarins.
				</p>
			</PageDisplayImage>

			<PageText style={{ paddingBottom: '100px' }}>
				<h1 className="paddingTop" style={{ width: "100%", color: "#BA0C2F", textTransform: "uppercase" }}>
					La qualité, c&apos;est toute une équipe qui <br />travaille au quotidien&nbsp;:
				</h1>
			</PageText>

			<PageText backgroundColor={"#eee8e2"}>
				<div
					className="fullSizeImage"
					style={{
						backgroundImage: `url("./images/pages/ControleQualite/controleQualite1.png")`
					}}
				/>
			</PageText >

			<PageVideo videoName={"demoControleQualite2"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} noControls={true}>
			</PageVideo>
		</>
	)
}
