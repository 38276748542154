import { useNavigate } from "react-router-dom";
import "../../style.css";
import "./pageStyle.css";
import { useLanguage } from '../../Contexts/LanguageContext.jsx';

export default function PageSubsections({ parentPath }) {
	const navigate = useNavigate();
	const { currentLanguage, setCurrentLanguage } = useLanguage()

	return (
		<button
			className="underline paddingTop"
			onClick={() => navigate(parentPath)}
			style={{ color: "#BA0C2F" }}
		>
			{currentLanguage === 'fr' ? (
				"Retour"
			) : (
				"Back"
			)}
		</button>
	)
}
