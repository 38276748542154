import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function MicrobiotePeau() {

	return (
		<>
			<PageTitle>MICROBIOTA<br /> & SKIN</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Skin microbiota is made up of all the microorganisms present on the skin’s surface.</span></span>
					<br />
					<span><span className="second">Our research highlights how commensal microorganisms present in skin play a key role in its ecosystem.</span></span>
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/MicrobioteEtPeau/microbioteEtPeau1.png"} imageSide="right" >
				<>
					<p className="paddingTopBottom">
						We work with academic partners to understand the impact of skin microbiota on the skin's main biological functions.
					</p>
					<div id="displayImageHighlight" className="marginTopBottom first">
						<div className="paddingTopBottom">
							<p>
								This research is essential to help us design our products so as to guarantee microbiota balance and preserve the sentinel strains that are essential for optimal microbiota-skin interactions.
							</p>
						</div>
					</div>

					<p className="paddingTopBottom">
						We have thus been able to highlight the role of a microbial strain, "Staphylococcus epidermidis", in regulating hydration.
					</p>

				</>
			</PageDisplayImage>
		</>
	)
}
