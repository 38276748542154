import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Conditionnement() {

	return (
		<>
			<PageTitle>Filling & Assembly</PageTitle>

			<PageText style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingBottom" style={{ width: "100%", color: "#BA0C2F", textAlign: "left", textTransform: "uppercase" }}>
					How are products packaged?
				</h1>
				<p>
					<span className="bold">Over 200 people</span> : packers, handlers, maintenance technicians and supervisors - work <span className="bold">24 hours a day</span>, in <span className="bold">3 shifts</span>, one of which is a short night shift.
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement1.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					The Filling & Assembly department assembles finished products
				</h1>
				<p className="paddingTop">
					In tubes, bottles and jars, using product bulk produced by the manufacturing unit and various packaging items.
					<br /><br />
					Finished Products are crated and palletized, ready for dispatch to our logistics center in Amiens (Northern France).
					<br /><br />
					<span className="bold">25 assembly lines</span> are dedicated to packaging items such as tubes, samples, jars or bottles ranging in size from 3ml to 400ml; a tube line only assembles tubes, a lotion line only assembles lotions.
					<br /><br />
					Only a few assembly lines are designed to assemble two different types of packaging however all lines can produce several different formats.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement2.png"} >
				<h1 className="paddingTop">
					Lines are increasingly <b>robotized</b> and equipped with <b>vision control systems</b>.
				</h1>
				<p className="paddingTop" >
					Assembly line operators’ main tasks include supervising, charging and controlling the lines to ensure that the end products produced correspond to what future customers expect.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Conditionnement/conditionnement3.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<h1 className="paddingTop">
					With nearly 3,000 different product references to produce,
				</h1>
				<p className="paddingTop">
					...changeovers from one product to another are very frequent.
					<br /><br />
					At the <span className="bold">end of a production run</span>, all tools that have come into contact with a cream or lotion are <span className="bold">dismantled for cleaning</span>.
					<br /><br />
					Finally, all the machine settings must be changed in order to produce the next run of finished products. Itis therefore very rare for a single production run (batch changeover) to last more than one day.
				</p>
			</PageDisplayImage>
		</>
	)
}
