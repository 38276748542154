import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../style.css";
import MenuButton from "../General/MenuButton";

export default function VideoButtons({ showVideo, showMenu, onLogoClick, onSoundClick, onPauseClick, onMenuClick, state, touchPointShow }) {

    const navigate = useNavigate();
    const [show, setShow] = useState(false);

    //Play/pause trigger by space bar up
    useEffect(() => {
        setShow(true);

        document.addEventListener('keyup', (e) => {
            if (e.code === "Space") onPauseClick();
        }, true)
        
        return () => { 
            document.removeEventListener('keyup', (e) => {
                if (e.code === "Space") onPauseClick();
            }, true)
        }
    }, [])

    return <>
            {(!touchPointShow && !showMenu && showVideo) &&
            <div 
                id="pause"
                onMouseDown={onPauseClick}
            >
                <img className={!state.playing && state.playedSeconds > 1 ? "paused" : ""} src="/images/general/paused.png" />
            </div>
            }

            <button
                id="logo"
                className={show? "show icon" : "hide icon"}
                style={{ zIndex: 3}}
                onClick={onLogoClick}
            >
                <img src="/images/general/logo.png" />
            </button>

            <button
                id="sound"
                className={show ? "show icon" : "hide icon"}
                onClick={onSoundClick}
            >
                <img src={state.muted ? "/images/general/soundOff.png" : "/images/general/soundOn.png"} />
            </button>

            <MenuButton z={3} showVideo={showVideo} showMenu={showMenu} onMenuClick={onMenuClick} animation={true} />
    </>
}
