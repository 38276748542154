import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function PoleReglementation() {

	return (
		<>
			<PageTitle>REGULATORY AFFAIRS<br />& COMPLIANCE</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Reglementation/reglementation1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					The general regulatory affairs and compliance department (RG) is responsible <span className="bold">for monitoring and ensuring the regulatory conformity of raw materials, formulas and finished products</span>.
					<br /><br />
					<span className="bold">A wide range of international regulations</span> govern, prohibit or restrict the use of certain ingredients and claims.
					<br /><br />
					The experts in this department are <span className="bold">responsible for the regulatory validation of formulas</span> as per marketing zones and the regulations in force, the product substance, the target consumer profile, the product application zone and the desired claims.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imageSide="center" imgSrc={"/images/pages/Reglementation/reglementation2.png"} backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingTop" style={{ color: "white" }} >
					They are also responsible for validating all media communication (packaging, website, brochures, social networks, etc.) and ensuring that substantiating data complies with expectations.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Discover our other centers</h2>
			</PageText>
		</>
	)
}
