/*
The code below is a repertory  of all the videos showcased.
The comment line situates on which page the videos below are located.
The name shouldn't be changed, only the links.
*/


export default {
	// Introduction
	intro: "",

	// Laboratoires visit video
	//laboratoires: "/films/FILM-LABO/CLARINS_VV_R&D_ENG.vtt",
	laboratoires: "",

	// Usine visit video
	//usine: "/films/FILM-USINE/CLARINS_VV_USINE_ENG.vtt",
	usine: "",

	// Laboratoires - Nos engagements
	//itwIssachar: "/films/FILMS-ITW/Nathalie/CLARINS_ITW01_ISSACHAR_ENG.vtt",
	itwIssachar: "",

	// Laboratoires - Biologie cellulaire
	//itwDorr1: "/films/FILMS-ITW/Marie/CLARINS_ITW02_DORR_ENG.vtt",
	itwDorr1: "",
	//demoExplant: "/films/FILM-EXPLANT/CLARINS_EXPLANTS_ENG.vtt",
	demoExplant: "",

	// Laboratoires - Phytochimie & actifs
	//itwGori: "/films/FILMS-ITW/Anthonin/CLARINS_ITW03_GORI_ENG.vtt",
	itwGori: "",

	// Laboratoires - Formulation
	//itwLeFur: "/films/FILMS-ITW/Agnes/CLARINS_ITW04_LEFUR_ENG.vtt",
	itwLeFur: "",
	//demoEmulsion: "/films/FILM-EMULSION/CLARINS_EMULSION_ENG.vtt",
	demoEmulsion: "",

	// Laboratoires - Microbiologie
	//itwLenogue: "/films/FILMS-ITW/Delphine/CLARINS_ITW06_LENOGUE_ENG.vtt",
	itwLenogue: "",

	// Laboratoires - Evaluation
	//itwGodet: "/films/FILMS-ITW/Dorothee/CLARINS_ITW05_GODET_ENG.vtt",
	itwGodet: "",
	//itwTizi: "/films/FILMS-ITW/Louisa/CLARINS_ITW07_TIZI_ENG.vtt",
	itwTizi: "",

	// Usine - Controle qualite
	demoControleQualite1: "",
	demoControleQualite2: "",

	// Usine - Conception packaging
	demoConceptionPackaging1: "",
	demoConceptionPackaging2: "",
}