import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import "../../../style.css";

export default function EvaluationClarins() {

	return (
		<>
			<PageTitle>évaluation</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Evaluation/evaluation1.png"} backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					Découvrez plus en détail deux activités du département Recherche et Evaluation.
					<br /><br />
					Avec Dorothée, <span className="bold">l&apos;évaluation de l&apos;efficacité de nos produits à l&apos;aide de méthodes d&apos;imagerie</span>.
					<br /><br />
					Avec Louisa, <span className="bold">l&apos;analyse sensorielle</span> qui permet de caractériser <span className="bold">le profil sensoriel</span> de nos produits.
				</p>
			</PageDisplayImage>

			<PageVideo videoName={"itwGodet"} hasSubtitle={true} style={{ paddingBottom: '60px' }}>
				Dorothée godet<br />Responsable évaluation Vivo
			</PageVideo>

			<PageVideo videoName={"itwTizi"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px' }}>
				Louisa tizi<br />Chargée de Projets évaluation vivo
			</PageVideo>
		</>
	)
}
