import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Ares() {

	return (
		<>
			<PageTitle>Ares</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Ares/ares1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					<span className="bold">ARES est l&apos;acronyme pour <q>&nbsp;Affaires Réglementaires et Évaluation de la sécurité&nbsp;</q>.</span>
					<br /><br />
					C&apos;est un service qui s&apos;assure de la <span className="bold">sécurité et de la conformité de nos produits avec les réglementations en vigueur dans les pays</span> dans lesquels nous les commercialisons.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} > Découvrez Nos différents pôles</h2>
			</PageText>
		</>
	)
}
