import "../../style.css";
import "./pageStyle.css";

export default function PageText({ children, animatedBlock = true, backgroundColor = "transparent", backgroundImage = "none", style = {} }) {

	return (
		<div
			id="page-background"
			className={`text ${backgroundImage !== "none" ? "gradient" : backgroundColor === "transparent" ? "transparent" : "beige"}`}
			style={style}
		>
			<div className={animatedBlock ? "page-text page-block" : "page-text no-animation paddingTopBottom"}>
				{children}
			</div>
		</div>
	)
}
