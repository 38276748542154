import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Soin() {

	return (
		<>
			<PageTitle>soin</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Ce laboratoire <span className="bold">développe l&apos;ensemble des produits de soin visage ou corps de nos marques</span>,</span></span>
					<br />
					<span><span className="second">ce qui représente des dizaines de produits lancés tous les ans et plusieurs centaines d&apos;essais</span></span>
					<br />
					<span><span className="third">afin de mettre au point la texture idéale qui satisfera nos consommateurs.</span></span>
				</p>
			</PageText>

			<div className="page-block" style={{ position: "absolute", zIndex: 1, padding: 0, top: "20vh", right: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/Soin/soin2.png" style={{ width: "100%" }} />
			</div>

			<PageDisplayImage imgSrc={"/images/pages/Soin/soin1.png"}>
				<p className="paddingTopBottom">
					<span className="bold">Certains produits requièrent une expertise particulière</span>, comme les produits éclaircissants, qui obéissent à une réglementation particulière ou les produits moussants.
				</p>
				<div id="displayImageHighlight">
					<div className="paddingTopBottom">
						<p>
							Nos principaux challenges sont de <span className="bold">stabiliser des mélanges contenant de nombreux ingrédients actifs</span> et d&apos;<span className="bold">atteindre les ambitieux objectifs d&apos;écoconception</span> fixés par nos marques sans faire de concession sur la sensorialité des produits.
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<div className="page-block" style={{ padding: 0, zIndex: 1, position: "absolute", bottom: "20vh", left: "0px", height: "0px", width: "30vw" }}>
				<img src="/images/pages/Soin/soin3.png" style={{ width: "100%" }} />
			</div>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Découvrez nos autres laboratoires de formulation</h2>
			</PageText>
		</>
	)
}
