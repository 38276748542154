import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../style.css"
import { useLanguage } from '../../Contexts/LanguageContext.jsx';

export default function TouchPoint({ size, thickness, time, x, y, path, name, muted, onEndTouchPoint }) {

	const { currentLanguage, setCurrentLanguage } = useLanguage()

	const navigate = useNavigate();
	const [audio] = useState(new Audio("sounds/touchpoint.m4a"));

	//When created, play audio if website not muted, and set up the touch point to disappear at the end of it's lasting time (set up in the videoPart files)
	useEffect(() => {
		audio.pause();
		if (!muted) audio.play();

		const timeout = setTimeout(() => {
			document.querySelector("#touchpoint-label").classList.add("endAnimation");
		}, (time - 0.5) * 1000);
		const timeout2 = setTimeout(() => {
			document.querySelector("#touchpoint-wrapper").classList.add("endAnimation");
		}, (time - 0.3) * 1000);

		const timeout3 = setTimeout(() => onEndTouchPoint(), time * 1000);

		return () => {
			clearTimeout(timeout);
			clearTimeout(timeout2);
			clearTimeout(timeout3);
		};
	}, [])

	useEffect(() => {
		audio.volume = muted ? 0 : 0.07;
	}, [muted]);

	//If the touch point is clicked, trigger en animation and navigate to page path
	const handleClick = () => {
		document.querySelector("#touchpoint-label").classList.add("endAnimation");
		document.querySelector("#touchpoint-wrapper").classList.add("touchpointClickedAnimation");

		const timeout = setTimeout(() => {
			onEndTouchPoint();
			navigate(path);
		}, 500)

		return () => clearTimeout(timeout);
	}

	return (
		<div
			id="touchpoint-wrapper"
			style={{ left: x, top: y, height: size, width: size }}
		>
			<div
				id="touchpoint"
			>
				<svg
					id="touchpoint-animation-wrapper"
				>
					<circle id="fill" style={{ strokeWidth: thickness }} cx="50%" cy="50%" r={size / 2 - 1 - thickness / 2} />
					<circle
						id="animation"
						style={{
							strokeWidth: thickness,
							strokeDasharray: 2 * Math.PI * (size / 2 - 1 - thickness / 2),
							strokeDashoffset: 2 * Math.PI * (size / 2 - 1 - thickness / 2),
							animationDuration: time + "s",
						}}
						cx="50%" cy="50%" r={size / 2 - 1 - thickness / 2}
					/>
					<circle id="outline" cx="50%" cy="50%" r={size / 2 - 1} />
					<circle id="outline" cx="50%" cy="50%" r={size / 2 - 1 - thickness} />
				</svg>

				<button
					id="touchpoint"
					className="icon"
					onClick={handleClick}
				>
					<img src="/images/general/touchpoint.png" />
				</button>

				<div id="touchpoint-label" >
					<h2>
						{currentLanguage === 'fr' ?
							<>
								EXPLORER<br /><span style={{ fontWeight: "lighter" }}>{name}</span>
							</> :
							<>
								EXPLORE<br /><span style={{ fontWeight: "lighter" }}>{name}</span>
							</>}
					</h2>
				</div>
			</div>

			<div id={"touchpoint-outline"} >
				<div />
			</div>
		</div>
	)
}
