import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import "../../../style.css";

export default function BiologieCellulaire() {

	return (
		<>
			<PageTitle>biologie<br />  cellulaire</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first">Allez à la rencontre de Marie qui va vous faire découvrir <span className="bold">les activités du laboratoire de biologie cellulaire</span>.</span></span>
					<br />
					<span><span className="second">Vous voulez en savoir plus sur <span className="bold">la méthode de préparation des prélèvements de peau ?</span></span></span>
				</p>
			</PageText>

			<PageText>
				<div
					style={{
						height: "30vh",
						width: "100%",
						borderRadius: "5px",
						backgroundImage: `url(${"/images/pages/BiologieCellulaire/biologieCellulaire1.png"})`,
						backgroundSize: "cover",
						backgroundPosition: "center",
						zIndex: 1
					}}
				/>
			</PageText>

			<PageVideo videoName={"itwDorr1"} style={{ paddingBottom: '100px' }}>
				Marie Dorr<br />Chef de projets Evaluation vitro
			</PageVideo>

			<PageVideo videoName={"demoExplant"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px' }}>
				méthode de préparation<br />des prélèvements de peau
			</PageVideo>
		</>
	)
}
