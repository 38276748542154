import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function StrategieInnovation() {

	return (
		<>
			<PageTitle>Our commitments</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first"><span className="bold">Welcome to Clarins Research & Development centre.</span></span></span>
					<br />
					<span><span className="second">R&D Director Nathalie Issachar shares a few words about our activities and our commitments to more responsible beauty.</span></span>
				</p>
			</PageText>

			<PageVideo videoName={"itwIssachar"}>
				Nathalie Issachar<br />RESEARCH AND DEVELOPMENT DIRECTOR
			</PageVideo>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements1.png"} backgroundImage="none" backgroundColor="#eee8e2" style={{ marginTop: '100px', paddingTop: '40px' }}>
				<h1 className="paddingTop">
					Research & Development<br />in a few figures
				</h1>

				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="padding" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left" }} >
							+150
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							NEW PRODUCTS<br />DEVELOPED/YEAR
						</p>
					</div>
				</div>

				<div id="displayImageHighlight">
					<div className="padding" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left" }} >
							+100
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							RESEARCH STUDIES/YEAR
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements2.png"} imageSide="right" backgroundImage="none" backgroundColor="#eee8e2" style={{ paddingBottom: '100px', paddingTop: '100px' }}>
				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="paddingTopBottom" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left", paddingLeft: "30vw" }} >
							+200
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							PLANTS IN THE<br />CLARINS HERBAR
						</p>
					</div>
				</div>

				<div id="displayImageHighlight">
					<div className="paddingTopBottom" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "10px" }}>
						<h2 style={{ color: "white", textAlign: "left", paddingLeft: "30vw" }} >
							+400
						</h2>
						<p style={{ paddingLeft: "0px" }} >
							NEW PLANTS<br />STUDIED/YEAR
						</p>
					</div>
				</div>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/NosEngagements/nosEngagements3.png"} imageSide="center" >
				<div id="displayImageHighlight">
					<div className="padding">
						<h2 style={{ color: "white", paddingLeft: "2vw", textAlign: "left", width: "45vw" }} >
							+90%
						</h2>
						<p style={{ paddingLeft: "2vw" }} >
							INGREDIENTS OF<br />NATURAL ORIGIN IN CARE
						</p>
					</div>
				</div>
			</PageDisplayImage>
		</>
	)
}
