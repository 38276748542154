import { useState, useEffect, useRef, useMemo } from "react";
import { gsap } from "gsap";
import "../../style.css";

export default function PageVideoControls({ state, onSoundClick, onFullscreenClick, onReplayClick, onPauseClick, onSeek, duration, buffering, ended }) {

    const [mouseOver, setMouseOver] = useState(false);
    const [checkPosition, setCheckPosition] = useState(true);
    const [show, setShow] = useState(false);
    const pauseIcon = useRef();
    const soundIcon = useRef();
    const fullscreenIcon = useRef();
    const timeline = useRef();
    const redTimeline = useRef();
    const whiteTimeline = useRef();
    const timelineSeek = useRef();

    // When the player is loaded and the duration of the video known, creates the gsap animation for the timeline moving.
    const timelineAnim = useMemo(() => {
        if (duration !== 0) {
            const timelineAnim = gsap.to(timeline.current, { x: timeline.current.clientWidth, ease: "none", duration: duration });
            state.playing ? timelineAnim.play() : timelineAnim.pause(0);

            return timelineAnim;
        }
    }, [duration]);

    //Sets the timeline to the beginning if the player is already loaded
    useEffect(() => {
        changePosition((window.innerWidth - timeline.current.clientWidth) / 2);
        setShow(true);
    }, []);

    // If the video is playing but buffering, pause it
    useEffect(() => {
        if (timelineAnim) state.playing && !buffering ? timelineAnim.play() : timelineAnim.pause();
    }, [state.playing, buffering]);

    //Timeline and buttons animation on hover
    useEffect(() => {
        gsap.killTweensOf(timelineSeek.current, "height");
        gsap.killTweensOf(soundIcon.current, "opacity");
        gsap.killTweensOf(pauseIcon.current, "opacity");
        gsap.killTweensOf(fullscreenIcon.current, "opacity");

        if (mouseOver) {
            gsap.to(timelineSeek.current, { y: 0, ease: "power1.inOut", duration: 0.2 });
            gsap.to(soundIcon.current, { opacity: 1, ease: "none", duration: 0.2 });
            gsap.to(pauseIcon.current, { opacity: 1, ease: "none", duration: 0.2 });
            gsap.to(fullscreenIcon.current, { opacity: 1, ease: "none", duration: 0.2 });

            timelineSeek.current.style.cursor = "pointer";

        } else {
            gsap.to(timelineSeek.current, { y: 25, ease: "power1.inOut", duration: !show ? 0 : 0.3 });
            gsap.to(soundIcon.current, { opacity: 0, ease: "none", duration: 0.2 });
            gsap.to(pauseIcon.current, { opacity: 0, ease: "none", duration: 0.2 });
            gsap.to(fullscreenIcon.current, { opacity: 0, ease: "none", duration: 0.2 });

        }
    }, [mouseOver]);

    //Everytime the state.played is updated, check if the timeline is at the right place. This is a security to prevent buffering bugs
    useEffect(() => {
        if (checkPosition) {
            var width = timeline.current.clientWidth;
            var position = (state.played * width) + (window.innerWidth - width) / 2;
            changePosition(position);
        }
    }, [state.played]);

    // After a new position is seeked, disable the position checking for 3 seconds, while the state.played gets up to date.
    useEffect(() => {
        if (!checkPosition) {
            const timeout = setTimeout(() => {
                setCheckPosition(true);
            }, 3000)

            return () => clearTimeout(timeout);
        }
    }, [checkPosition]);

    //Calculates the new position of the timeline when it's clicked
    const handleSeekMouseDown = event => {
        var newPosition = event.clientX;
        var width = timeline.current.clientWidth;
        changePosition(newPosition);
        var seekPos = (newPosition - ((window.innerWidth - width) / 2)) / width;
        onSeek(seekPos);
        setCheckPosition(false);
    }

    //Change position of the timeline
    const changePosition = (position) => {
        gsap.killTweensOf(redTimeline.current);
        gsap.killTweensOf(whiteTimeline.current);
        gsap.to(redTimeline.current, { x: 0, ease: "none", duration: 0.1 });
        gsap.to(whiteTimeline.current, { x: 0, ease: "none", duration: 0.1 });
        gsap.killTweensOf(timelineSeek.current, "x");

        gsap.to(timelineSeek.current, { x: position - timeline.current.getBoundingClientRect().x, ease: "power1.inOut", duration: 0.3 });
    }

    const handleReplayClick = () => {
        changePosition((window.innerWidth - timeline.current.clientWidth) / 2);
        onReplayClick();
    }

    return (
        <>
            <div
                id="pageVideoTimeline"
                onMouseEnter={() => setMouseOver(true)}
                onMouseLeave={() => setMouseOver(false)}
            >
                <div id="screenPause" onMouseDown={onPauseClick} />

                <div id="timeline" ref={timeline} style={{ height: 20 }} >
                    <div ref={timelineSeek} id="timelineSeek" style={{ height: "100%" }} onMouseDown={handleSeekMouseDown} >
                        <div ref={redTimeline} style={{ backgroundColor: "#BA0C2F" }} />
                        <div ref={whiteTimeline} style={{ backgroundColor: "#ECE6E0" }} />
                    </div>
                </div>


            </div>
            <div
                id="pageVideoControls"
                onMouseEnter={() => setMouseOver(true)}
                onMouseLeave={() => setMouseOver(false)}
            >
                <button id="fullscreen" className="icon" onClick={onFullscreenClick} >
                    <img ref={fullscreenIcon} src="/images/general/fullscreen.png" />
                </button>

                {!ended ?
                    <button id="play" className="icon" onClick={onPauseClick} >
                        <img ref={pauseIcon} src={!state.playing ? "/images/general/play_small.png" : "/images/general/paused_small.png"} />
                    </button>
                    :
                    <button id="play" className="icon" onClick={handleReplayClick} >
                        <img ref={pauseIcon} src="/images/general/replay.png" />
                    </button>
                }

                <button id="sound" className="icon" onClick={onSoundClick} >
                    <img ref={soundIcon} src={!state.muted ? "/images/general/soundOff.png" : "/images/general/soundOn.png"} />
                </button>


            </div>
        </>
    );

}
