import { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import MenuButton from "../General/MenuButton";
import "../../style.css";

export default function PageBackground({ height, visitPath, onPageClosing, windowRatio }) {
    const navigate = useNavigate();
    const rect = useRef();
    const svg = useRef();

    const [backgroundHeight, setBackgroudHeight] = useState(window.innerHeight);
    const [svgHeight, setSvgHeight] = useState(0);
    const [show, setShow] = useState(false);

    useEffect(() => {  setShow(true) }, []);

    //Calculate the height of the svg background, with an added "s" safety margin
    useEffect(() => {
        var s = windowRatio >= 0.7 ? 500 : 200;
        setBackgroudHeight(Math.max(window.innerHeight * 1, height + s));
    }, [height, windowRatio]);

    useEffect(() => {
        setSvgHeight(backgroundHeight * 100 / window.innerWidth);
    }, [backgroundHeight]);

    //fade out animation when top exit button is clicked
    const closePageFromTop = () => {
        var animation = document.getElementById("closePageFromTop");
        setTimeout(() => animation.beginElement(), 1200);
        setShow(false);

        onPageClosing();
        setTimeout(() => navigate(visitPath), 3000);
    };

    //fade out animation when bottom exit button is clicked
    const closePageFromBottom = () => {
        var animation = document.getElementById("closePageFromBottom");
        setTimeout(() => animation.beginElement(), 1200);
        setShow(false);

        onPageClosing();
        setTimeout(() => navigate(visitPath), 3000);
    };

    return <>
        <div id="page-background-wrapper" style={{ height: `${backgroundHeight}px` }} >
            <svg ref={svg} style={{ display: "block" }} viewBox={`0 0 100 ${svgHeight}`} width="100%"  >
                <defs>
                    <mask id="mask" x="0" y="0" width="100%" height="100%">
                        <rect x="0" y="0" width="100%" height="100%" fill="#fff" />

                        <circle
                            id="page-circle-top"
                            cx={(window.innerWidth - 55) * 100 / window.innerWidth}
                            cy={55 * svgHeight / backgroundHeight}
                        >
                            <animate
                                attributeName="r"
                                begin="0s"
                                dur="1.2s"
                                repeatCount="1"
                                fill="freeze"
                                values={windowRatio > 0.7 ? "150;130;70;30;15;9;10" : "300;260;140;60;30;18;20"}
                            />
                            <animate
                                id="closePageFromTop"
                                begin="indefinite"
                                attributeName="r"
                                dur="1.2s"
                                repeatCount="1"
                                values={windowRatio > 0.7 ? "10;150" : "20;300"}
                                fill="freeze"
                            />
                        </circle>

                        <circle
                            id="page-circle-bottom"
                            cx="50"
                            cy={svgHeight}
                            r={windowRatio > 0.7 ? 10 : 20}
                        >
                            <animate
                                id="closePageFromBottom"
                                begin="indefinite"
                                attributeName="r"
                                dur="1.2s"
                                repeatCount="1"
                                values={windowRatio > 0.7 ? "10;150" : "20;300"}
                                fill="freeze"
                            />
                        </circle>

                    </mask>
                </defs>
                <rect ref={rect} x="0" y="0" width="100" height={svgHeight} mask="url(#mask)" fillOpacity="1" fill="#fff" />
            </svg>
        </div>

        <div className={show ? "showQuick" : "hide"} id="page-background-buttons" style={{ height: `${backgroundHeight}px` }} >
            <button id="logo" className="icon" style={{ zIndex: 4 }} onClick={() => navigate("/")} >
                <img src="/images/general/logo-black.png" />
            </button>
            <MenuButton
                style={{
                    position: "relative", bottom: `${windowRatio >= 1 ? 50 : 15}px`,
                    top: "auto", right: 0,
                    zIndex: 4,
                }}
                z={4} onMenuClick={closePageFromBottom} animation={false}
            />
        </div>

        <section style={{ width: "100vw", minWidth: "100vw" }} >
            <MenuButton z={4} show={true} onMenuClick={closePageFromTop} animation={false} />
        </section>
    </>
}
