import "../../style.css";
import "./pageStyle.css";

export default function PageBanner({ children, imgSrc, z }) {

    return (
    <div className="page-block" style={{ width: "100vw" }}>
        <div className="paddingTopBottom" style={{ backgroundColor: "#BA0C2F", width: "100vw", display: "flex", flexDirection: "column", alignItems: "center" }} >
            <p className="paddingTopBottom" style={{ width: "70%", color: "white", textAlign: "center" }}>
                {children}
            </p>
        </div>
        <div
            className="flex"
            style={{
                zIndex: z,
                height: "calc(0.30*100vw)",
                width: "100vw",
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: "cover",
                paddingTop: "0px"
            }}
        >
        </div>
    </div>
    )
}
