import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import "../../../style.css";

export default function Formulation() {

	return (
		<>
			<PageTitle>FORMULATION</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first"><span className="bold">Welcome to the formulation laboratories !</span></span></span>
					<br />
					<span><span className="second">Agnès is going to tell you all about her job at the heart of the skin care laboratory.
					</span></span>
					<br />
					<span><span className="third">You will also discover the key steps in creating an emulsion, and the specific missions assigned to our three formulation laboratories.</span></span>
				</p>
			</PageText>

			<PageVideo videoName={"itwLeFur"} style={{ marginBottom: '100px' }}>
				Agnès Le Fur<br />SKIN CARE LABOROTORY MANAGER
			</PageVideo>

			<PageVideo videoName={"demoEmulsion"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				How to make an emulsion
			</PageVideo>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }}> Discover our three formulation laboratories </h2>
			</PageText>
		</>
	)
}
