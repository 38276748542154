import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function PoleEnregistrements() {

	return (
		<>
			<PageTitle>Enregistrements<br />internationaux et DIP</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Enregistrements/enregistrements1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p>
					Le pôle enregistrements internationaux et DIP <span className="bold">procède aux formalités administratives</span> préalables nécessaires à la mise sur le marché des produits cosmétiques dans les différents pays dans lesquels ils seront distribués.
					<br /><br />
					<span className="bold">L&apos;équipe se charge ainsi de garantir la conformité réglementaire des DIP</span> (dossier information produit) des produits cosmétiques mis sur le marché et s&apos;occupe de la <span className="bold">gestion des enregistrements sanitaires</span> des matières premières et produits.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Enregistrements/enregistrements2.png"} style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p className="paddingTopBottom">
					<span className="bold">Le DIP est la <q>&nbsp;carte d&apos;identité&nbsp;</q></span> qui permet au produit de pouvoir être commercialisé.
					<br /><br />
					À disposition des autorités de contrôle, il doit contenir les informations suivantes en provenance des différents services contributeurs (R&D, Opérations, Pilote, Fabrication, Qualité…) :
				</p>
				<div id="displayImageHighlight" className="marginTopBottom first">
					<div className="paddingTopBottom">
						<p>
							•  L&apos;identification et la description du produit (formule, spécifications, étiquetage,…)
							<br /><br />
							• Le Rapport d&apos;Evaluation de la Sécurité incluant les résultats de stabilité, compatibilité, sécurité microbiologique, innocuité, retours de cosmétovigilance…
							<br /><br />
							• Les preuves de l&apos;efficacité revendiquée
							<br /><br />
							• La description de la méthode de fabrication et une déclaration de conformité aux BPF
						</p>
					</div>
				</div>
				<p className="paddingTopBottom">
					<span className="bold">Le pôle s&apos;assure de la bonne complétude des DIP</span> avant la mise sur le marché et veille à ce qu&apos;ils restent à jour tout au long de leur durée de vie sur le marché et même 10 ans après l&apos;arrêt de leur commercialisation.
				</p>
			</PageDisplayImage>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} >Découvrez nos autres pôles</h2>
			</PageText>
		</>
	)
}
