// oidcConfig.js
import { UserManager, WebStorageStateStore } from 'oidc-client-ts';

const oidcConfig = {
    authority: 'https://login.microsoftonline.com/d1f96e1f-4ed8-4af6-baf7-56b493a33a1f', // Replace with your Azure AD tenant ID
    client_id: 'f1a21f67-05f3-4f14-ad5b-c248676bfc81', // Application (customer) ID
    redirect_uri: 'https://www.immersivetourpontoise.clarins.com/', // Replace with your redirect URI
    response_type: 'code',
    scope: 'openid profile email', // Adjust scopes as needed
    post_logout_redirect_uri: 'https://www.immersivetourpontoise.clarins.com/', // Optional logout URL
    userStore: new WebStorageStateStore({ store: window.localStorage }),
    usePKCE: true  // Enable PKCE for enhanced security
};

export const userManager = new UserManager(oidcConfig);

// Add event handlers
userManager.events.addUserLoaded(user => {
    console.log('User loaded:', user);
});

userManager.events.addUserUnloaded(() => {
    console.log('User unloaded');
});

userManager.events.addAccessTokenExpiring(() => {
    console.log('Access token expiring');
});

userManager.events.addAccessTokenExpired(() => {
    console.log('Access token expired');
});

userManager.events.addUserSignedOut(() => {
    console.log('User signed out');
});
