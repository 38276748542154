import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import "../../../style.css";

export default function Formulation() {

	return (
		<>
			<PageTitle>formulation</PageTitle>

			<PageText animatedBlock={false}>
				<p>
					<span><span className="first"><span className="bold">Bienvenue dans les laboratoires de formulation !</span></span></span>
					<br />
					<span><span className="second">Agnès vous parle de son métier au sein du laboratoire de développement soin.
					</span></span>
					<br />
					<span><span className="third">Vous pourrez aussi découvrir les étapes-clés de la réalisation d&apos;une émulsion, puis les missions spécifiques de nos trois laboratoires de formulation.</span></span>
				</p>
			</PageText>

			<PageVideo videoName={"itwLeFur"} style={{ marginBottom: '100px' }}>
				Agnès Le Fur<br />Responsable Laboratoire Formulation Soin
			</PageVideo>

			<PageVideo videoName={"demoEmulsion"} backgroundImage={"linear-gradient(#eee8e2, #fff)"} style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				Comment réaliser une émulsion ?
			</PageVideo>

			<PageText animatedBlock={false}>
				<h2 style={{ width: "100%", textTransform: "uppercase" }} > Découvrez nos 3 laboratoires de formulation </h2>
			</PageText>
		</>
	)
}
