import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageText from "../../../Components/Pages/PageText.jsx";
import PageVideo from "../../../Components/Pages/PageVideo.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Microbiologie() {

	return (
		<>
			<PageTitle>microbiologie</PageTitle>

			<PageVideo videoName={"itwLenogue"} style={{ paddingTop: '40px' }}>
				Delphine lenogue<br />Responsable Laboratoires Microbiologie
			</PageVideo>

			<PageText style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				<p>
					Nos équipes d&apos;experts microbiologistes travaillent au quotidien pour assurer la <span className="bold">conservation de nos produits pendant toute leur durée d&apos;utilisation</span> par les consommateurs tout en respectant l&apos;équilibre de leur peau.
					<br />
					<br />
					<span className="bold">Le département est composé de trois équipes qui interviennent à des moments différents du processus de développement</span>.
				</p>
			</PageText>

			<PageDisplayImage imgSrc={"/images/pages/Microbiologie/microbiologie1.png"} style={{ paddingTop: '40px', paddingBottom: '40px' }}>
				<p className="paddingTopBottom">
					En amont du développement des formules, une équipe dédiée aux travaux de recherche met en place une dizaine de collaborations chaque année, avec pour <span className="bold">objectif une meilleure connaissance des microorganismes de la peau, mais aussi de l&apos;environnement</span>.
				</p>
				<div id="displayImageHighlight" className="marginTopBottom">
					<div className="paddingTopBottom">
						<p>
							Nos travaux de recherche les plus innovants (microbiologie, biologie, phytochimie, formulation, évaluation clinique) sont partagés dans des <span className="bold">revues scientifiques</span> à comité de lecture ou lors de <span className="bold">congrès internationaux</span>.
							<br /><br />
							Ils peuvent aussi faire l&apos;objet de <span className="bold">dépôts de brevets</span>.
						</p>
					</div>
				</div>
				<p className="paddingTopBottom">
					Cette équipe travaille également à <span className="bold">élaborer des combinaisons de molécules conservatrices, équilibre subtil entre efficacité antimicrobienne et bonne tolérance cutanée</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Microbiologie/microbiologie2.png"} imageSide="right" backgroundImage="none" backgroundColor="#BA0D2F" style={{ paddingTop: '60px', paddingBottom: '60px' }}>
				<p style={{ color: "white" }} >
					Les deux autres équipes de microbiologistes sont dédiées :
					<br /><br />
					• À l&apos;<span className="bold">évaluation de l&apos;efficacité des systèmes de conservateurs</span> dans les formules développées
					<br /><br />
					•  Aux <span className="bold">contrôles microbiologiques de l&apos;environnement industriel</span>, des <span className="bold">matières premières</span>, mais également des <span className="bold">produits finis</span> avant leur mise sur le marché.
					<br /><br />
					<span className="bold">Sécurité et qualité sont les maîtres mots de toutes nos activités</span>.
				</p>
			</PageDisplayImage>
		</>
	)
}
