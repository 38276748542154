import PageTitle from "../../../Components/Pages/PageTitle.jsx";
import PageDisplayImage from "../../../Components/Pages/PageDisplayImage.jsx";
import "../../../style.css";

export default function Reformulation() {

	return (
		<>
			<PageTitle>Reformulation<br />& Catalogue</PageTitle>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation1.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					Le laboratoire a pour mission essentielle d&apos;assurer la pérennité de nos produits sur le marché.
					<br /><br />
					<span className="bold">Les problématiques liées à nos matières premières sont de plus en plus fréquentes.</span>
					<br /><br />
					Il peut s&apos;agir de contraintes réglementaires, qui peuvent imposer <span className="bold">des restrictions ou des interdictions sur certaines substances</span>.
					<br /><br />
					Mais nous pouvons également faire face à <span className="bold">des arrêts de commercialisation de matières premières</span> de la part de nos fournisseurs.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation2.png"} imageSide="center" >
				<p>
					Afin d&apos;assurer la pérennité des produits sur le marché, le laboratoire RMC reformule les produits impactés par ces différentes problématiques.
					<br /><br />
					Le défi majeur de toute reformulation est que <span className="bold">le changement de formule ne soit pas perceptible par nos consommateurs</span>.
				</p>
			</PageDisplayImage>

			<PageDisplayImage imgSrc={"/images/pages/Reformulation/reformulation3.png"} imageSide="right" backgroundImage="none" backgroundColor="transparent">
				<p>
					La durée d&apos;une reformulation varie en fonction de sa complexité et de son urgence, allant de 3 à 12 mois.
					<br /><br />
					Les <span className="bold">validations des reformulations</span> passent par <span className="bold">une série de tests</span>, allant des essais de stabilité aux tests d&apos;efficacité, microbiologiques et sensoriels.
				</p>
			</PageDisplayImage>
		</>
	)
}
